@keyframes opacity-on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity-off {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
