@mixin cover-fixed {
  position: fixed;
  inset: 0;
}

@mixin cover {
  position: absolute;
  inset: 0;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: " ";
    height: 0;
    display: block;
  }
}

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
}
