.notification {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: all $transition-default ease-in-out;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
  z-index: 5000;
}

.notificationContainer {
  position: fixed;
  top: 75px;
  min-height: 200px;
  z-index: 2650;

  .container {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2650;
  }

  .toast {
    &.alert-success,
    &.alert-danger {
      padding-left: 15px;
      padding-right: 15px;
      position: fixed;
      top: 90px;
      right: 20px;
      width: 400px;
      z-index: 2700;

      .alert-message {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        padding-top: 3px
      }

      .glyphicons-remove-sign,
      .glyphicons-ok-sign {
        font-size: 14px;
        font-weight: 400;
        top: 7px
      }

      .danger-message,
      .success-message {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        padding-top: 3px
      }
    }
  }
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #721c24;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #155724;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #856404;
}

.alert-info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.new-alert {
  width: 95%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin: auto
}