@use "../global/mixins";

#overlay {
  @include mixins.cover-fixed;

  z-index: $zindex-modal;
  overflow-y: scroll;

  [aria-controls="modal"] {
    cursor: pointer;
  }

  &.active {
    background: rgb(0 0 0 / 50%);
  }
}

#modal {
  width: 100%;
  height: 100%;
  background-color: $background-white;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  outline: none;
  text-align: left;
  pointer-events: all;
  overflow: scroll;

  // overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  // transition: all $transition-default ease-in-out;
  // transform: translateY(110%);
  section {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
  }

  .upload-msg {
    text-align: left;
    padding: 54px;
    font-size: 22px;
    color: #aaa;
    width: 248px;
    margin: 0 auto;
    border: 1px solid #aaa;
  }

  .upload-img .upload-img-wrap,
  .upload-img.ready .upload-msg {
    display: none;
  }

  .upload-img.ready {
    display: block;
  }

  .upload-img.ready .upload-img-wrap {
    display: block;
  }

  .img-container {
    position: relative;
    margin: 0 auto;
    padding: 0 10px;
  }

  .grid {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .upload-img-wrap {
    .btn-group {
      display: block;
      margin: 0 auto;
    }
  }

  .upload-rotate {
    display: flex;
    justify-content: center;
  }
}

#modal-live {
  position: absolute;
  clip: rect(0 0 0 0);
}