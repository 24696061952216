.disable-scroll {
  overflow: hidden;

  @media only screen and (width <= 767px) {
    #container {
      height: 1px;
      overflow: hidden;
    }
  }
}

.medium-only {
  display: none;

  @media #{$medium} {
    display: inherit;
  }

  @media only screen and (width >= 992px) {
    display: none;
  }
}

.medium-large {
  display: none;

  @media #{$medium} {
    display: inherit;
  }
}

.small-only {
  display: inherit;

  @media #{$medium} {
    display: none;
  }
}

var {
  &.empty,
  &.none {
    display: block;
    margin: 1.25rem 0;
    font-size: 2rem;
    font-style: italic;
    font-weight: lighter;
    color: fade(black, 25%);
  }

  &.empty {
    &::after {
      content: "— empty —";
    }
  }

  &.none {
    &::after {
      content: "— none —";
    }
  }
}