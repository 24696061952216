@media print {
  .body-footer,
  #overlay,
  .progress,
  .notification,
  .modal,
  .modal-fullscreen,
  #menu-mobile,
  .sheet-overlay,
  #context-search-bar,
  .hidden-xs-down,
  header,
  footer,
  .help {
    display: none;
  }

  #container,
  .main {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  body {
    background-color: #fff;
    font-family: serif;
  }

  #bpa-reports {
    -webkit-print-color-adjust: exact;
    color-adjust: exact; // experimental browser support

    #bpa-report-xml-result-list header h1,
    header h1.title {
      color: #000;
    }

    section.report-selection {
      display: none;
    }

    header,
    section.tablelist .formview {
      display: block;
    }

    section {
      margin: 0;
    }

    .table-responsive {
      overflow-x: inherit;
    }

    section.tablelist .formview .form-item,
    .table-sm th,
    .table-sm td {
      font-size: 1rem;
      background-color: #fff;
      color: #000;
    }

    .table-sm th {
      background-color: #eee;
    }

    section.tablelist .formview {
      display: table;

      .form-item {
        display: table-row;
      }
    }

    section.tablelist .formview .form-item {
      .form-label,
      dfn {
        display: table-cell;
        padding-right: 1rem;
      }
    }

    section.image img {
      max-width: 100%;
    }

    // deal with charts and tables
    #chart-type-selector,
    .chart-data-source,
    nav.tablist {
      display: none;
    }

    div.tabpanels {
      div:first-child,
      #bpa-report-xml-result-list header,
      section.tablelist:first-child,
      div#panel-table {
        display: block !important;
      }
    }
  }
}