.dots-loading-overlay::after {
  z-index: $zindex-fullscreen-loading-mask;
}

body .dots-loading .dots-box {
  z-index: $zindex-fullscreen-loading-mask + 1;
}

.loading-overlay {
  z-index: 9999;
  height: 60px;
}