@use "../global/mixins";

#timekeeper,
.timekeeper {
  .section-error-indicator {
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    font-size: .5rem;
    font-weight: bold;
    text-align: center;
    color: $error;

    @media #{$medium} {
      bottom: -12px;
      font-size: 0.625rem;
    }

    @media only screen and (width >= 992px) {
      bottom: -6px;
      font-size: .75rem;
    }
  }

  section h2 {
    margin-bottom: 4px;
    margin-top: 10px;
    padding: 0;
  }

  [data-accordian-entry] {
    margin-top: 0;
  }

  .instruction {
    margin-top: 10px;

    p {
      margin: 0;
    }
  }

  .section-entries .timekeeper-section-controls {
    padding: 10px 0;
  }

  .timekeeper-section-controls {
    .btn-block {
      @media only screen and (width <= 767px) {
        margin-bottom: 5px;
      }
    }
  }

  // Override to remove interactive styles for daily summary section
  // which doesn't expand.
  #daily-summary .section-summary {
    cursor: default;
  }

  .section-summary-text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 82px);

    @media #{$medium} {
      width: calc(100% - 120px);
      line-height: 48px;
      font-size: 24px;
    }

    @media only screen and (width >= 992px) {
      width: calc(100% - 180px);
      margin-left: 20px;
      line-height: 64px;
      font-size: 36px;
    }

    .count {
      font-size: 24px;
      display: inline-block;
      text-align: center;
      min-width: 60px;
      vertical-align: middle;
      margin-right: 10px;

      @media #{$medium} {
        min-width: 120px;
        font-size: 48px;
        margin-right: 10px;
      }

      @media only screen and (width >= 992px) {
        min-width: 140px;
        font-size: 64px;
        margin-right: 20px;
      }
    }

    .count-label {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 100px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media #{$medium} {
        width: calc(100% - 240px);
      }

      &.no-digits {
        margin-left: 20px;
      }
    }
  }

  #payroll-summary .count-label {
    width: calc(100% - 140px);

    @media #{$medium} {
      width: calc(100% - 280px);
    }

    @media only screen and (width >= 992px) {
      width: calc(100% - 440px);
    }
  }

  .standard-list {
    overflow-y: hidden;
    margin-bottom: 0;
  }

  .entry-summary {
    @include mixins.clearfix;

    border-bottom: #f1f6f6 1px solid;
    cursor: pointer;
  }

  #payroll-summary .entry-summary,
  #period-summary .entry-summary {
    cursor: default;
  }

  .entry-error-indicator {
    display: inline-block;
    vertical-align: 14px;
    margin-right: 6px;
    cursor: pointer;
    transition: transform $transition-slow ease-in-out;

    .glyphicons {
      color: $error;
    }

    &:hover {
      transform: scale(1.04);
    }

    @media #{$medium} {
      vertical-align: 32px;
      margin-right: 12px;
      margin-top: 18px;

      .glyphicons {
        font-size: 2.25rem;
      }
    }

    @media only screen and (width <= 767px) {
      margin-top: 9px;
    }
  }

  .entry-summary-content {
    display: flex;
    padding: 0 0 0 8px;

    &:hover {
      background: $primary-lightest;
    }
  }

  .time-logged,
  .time-period {
    flex: 0 1 auto;
  }

  .time-logged {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .entry-hours,
    .break-hours,
    .amount-entry {
      flex: 0 1 auto;
    }
  }

  .actions {
    flex: 1 0 44px;
    position: relative;
    text-align: right;
    overflow: hidden;
    padding: 0;
  }

  .action-items {
    padding: 0;

    @media #{$medium} {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      background: $sheet-background;
      border-left: 4px solid $primary;
      transition: transform $transition-slow ease-in-out;
      transform: translateX(100%);
    }
  }

  .action-item {
    display: block;
    list-style: none;

    @media #{$medium} {
      display: inline-block;
      vertical-align: middle;
    }

    @media only screen and (width <= 767px) {
      .actionable {
        width: 100%;
        font-size: 1.5rem;
      }
    }

    &.cancel {
      @media #{$medium} {
        display: none;
      }
    }

    .button-label {
      @media #{$medium} {
        margin-top: 3px;
        display: block;
        font-size: .75rem;
        font-weight: 800;
      }
    }

    .glyphicons {
      display: none;

      @media #{$medium} {
        display: block;
        font-size: 1.5rem;
      }
    }
  }

  .action-more {
    top: 0;
    cursor: pointer;
    vertical-align: middle;
    transition: background $transition-slow ease-in-out;
    font-size: 2rem;
    width: 60px;
    padding: 7px 2px;
    float: right;

    @media #{$medium} {
      padding: 22px 8px;
      float: none;

      &[aria-expanded="true"] {
        background: $sheet-background;
      }
    }
  }

  .action-edit,
  .action-delete,
  .action-view {
    color: $main;
    background: $sheet-background;
    padding: 0;
    margin: 0;

    .button-simple {
      @media #{$medium} {
        height: 84px;
        margin: 0;

        .glyphicons {
          font-size: 24px;
        }
      }
    }
  }

  .time-logged {
    padding-right: 10px;
    margin-right: 10px;
    border-right: $main 2px solid;
    min-width: 110px;

    &.amount {
      border-right: none;
    }

    @media #{$medium} {
      min-width: 150px;
    }
  }

  .time-period {
    font-size: 1rem;

    @media #{$medium} {
      font-size: 1.75rem;

      .time-type {
        font-size: 1rem;
      }
    }
  }

  .time-period,
  .period-summary-text {
    vertical-align: 14px;
    padding-bottom: 10px;

    @media #{$medium} {
      vertical-align: 20px;
    }
  }

  .entry-hours,
  .break-hours {
    @media only screen and (width <= 767px) {
      font-size: .75rem;
    }
  }

  .time-type,
  .break-hours {
    display: block;
  }

  .hours-value {
    font-size: 1rem;

    @media #{$medium} {
      font-size: 1.75rem;
    }
  }

  .table-timekeeper-weekly {
    .flex-balance-title {
      display: inline-block;
      float: right;
    }

    th.flex-balance-value {
      border-left: 8px solid #bdc0c3;
      text-align: right;
    }

    &.table tbody th.flex-balance-value {
      background-color: #3d4144;
      color: white;
    }

    tbody > tr > th,
    tbody > tr > td {
      vertical-align: middle;
    }
  }
}

#payroll-summary {
  h2 {
    margin: 0;
    padding-bottom: 6px;
    padding-top: 10px;
  }
}

#timekeeper-day-selector {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 0;
  position: relative;

  .timekeeper-period-day {
    display: inline-block;
    position: relative;
    width: 100px;
    list-style: none;
    text-align: center;
    transition: all $transition-slow ease-in-out;
    border-top: 4px solid $gray-darkest;
    background: $gray-lightest;

    &.has-entries::after {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $gray;
      content: "";
    }

    &.has-entries.has-errors::after {
      background-color: $error;
    }

    &[aria-pressed] {
      background: $gray;
      padding: 6px 12px 18px;
      color: $typography-color;
    }

    &[aria-pressed].has-entries::after {
      background: $gray-light;
    }

    &[aria-pressed].has-entries.has-errors::after {
      background-color: $error;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 6px 12px 18px;
      color: $primary-darker;

      &:hover {
        color: $primary-dark;
      }
    }
  }
}

.timekeeper-period-selector {
  .form-item {
    display: block;
  }

  .form-input {
    display: block;
    flex: none;
    text-align: left;
  }

  .form-label {
    text-align: left;
  }

  label.form-label {
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
    padding-top: initial;
  }

  @media #{$medium} {
    .formview {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .period-select {
      a {
        color: $button;
      }

      .glyphicons {
        margin-top: -3px;
      }

      select {
        width: 10px;
        flex-basis: 10px;
      }
    }

    .form-item2.period-select {
      .select-button-wrap {
        clear: left;
      }

      select {
        width: initial;
      }
    }

    .formview .form-item-fieldbutton-wrap {
      display: flex;
      flex-wrap: 1;
    }

    #timekeeper-period-select.form-input {
      flex-grow: 1;
    }

    .form-item {
      flex: 1 0 auto;

      &.month-select {
        flex: 0 1 300px;

        #timekeeper-median-month,
        #timekeeper-median-filter {
          display: inline-block;
        }

        #timekeeper-median-month {
          width: calc(100% - 104px);
        }

        #timekeeper-median-filter {
          margin-left: 0.25em;
        }
      }

      &.period-select {
        #timekeeper-action-period-select,
        #timekeeper-period-select {
          display: inline-block;
          vertical-align: top;
        }

        #timekeeper-period-select {
          height: 43px;

          // width: ~'calc(100% - 74px)';
        }

        #timekeeper-action-period-select {
          margin-left: 0.25em;
        }
      }
    }
  }
}

.fullscreen {
  .progress {
    top: 0;
  }

  .notification {
    top: 0;
  }

  .timesheet-popover {
    display: none;
  }
}

.timekeeper-view-template label.form-label {
  margin-top: 0;
}

@media #{$medium} {
  #timekeeper-period {
    position: relative;

    .header-actions {
      position: absolute;
      right: 0;
      top: 0;

      // reset the container to be top-right aligned with <h1>
      margin-top: 4px;
      margin-right: -3px;
      padding-top: 10px; // push button container off top to be centered vertically
      padding-right: 10px; // push button container off right to be visually balanced
    }
  }
}

@media only screen and (width <= 767px) {
  #timekeeper-action-period-select,
  #timekeeper-median-filter,
  #timekeeper-period .header-actions a {
    margin-top: 0.25em;
    width: 100%;
  }
}

#tabDropdownDiv {
  margin-left: 6px;
  opacity: 0;
  transition: visibility 0s, opacity $transition-default linear;
}

#tabDropdownSelect {
  width: 0;
  transition: width $transition-default linear;
}

#tabMessage {
  font-size: 0.8rem;
  text-align: center;
  transition: visibility 0s, opacity $transition-slow linear;
}

#tabDropdownContainer {
  transition: min-height $transition-default linear;
}

.vertical-centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error-pointer {
  cursor: pointer;
}

#timekeeper,
.timekeeper {
  #container.expand {
    width: auto;
    max-width: 95%;

    #main {
      margin: auto;
      max-width: 95%;
    }
  }
}