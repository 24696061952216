@use "sass:color";
@use '../global/mixins';
@import '../global/animations';

// Form selector codepicker styles
body.autocomplete-visible {
  @media #{$small} {
    overflow: hidden;

    #container {
      height: 1px;
      overflow: hidden;
    }
  }
}

.field.form-selector {
  input {
    height: $field-height; // instead of trying to derive button height, set set input hieght to Chrome's current default input field size
  }
}

.form-selector {
  // This is for when autocomplete are in horizontal forms (in desktop)
  @media #{$medium} {
    flex: 1 0 auto;
  }

  .field-wrap {
    display: flex;

    input[aria-autocomplete][role] {
      // KLUDGE: IE Fix, please explain?
      &::-ms-clear {
        display: none;
      }

      width: auto;
      flex-grow: 1;
    }

    button {
      margin: 0; // same as the field
      top: 0; // we're not showing the top border
      right: 0; // match the field's padding-right

      @include button-color(#fff, $button);

      border-radius: 0 $border-radius $border-radius 0;
      border: 1px solid $button;
      border-left-width: 0;

      // line-height: 1.8rem;
      font-size: 0.875rem;
      padding: 0;
      width: $field-height; // make the button square
    }
  }

  &.focused {
    @media #{$small} {
      margin-bottom: 0; // undo fieldset > div

      [role="listbox"] {
        position: inherit;
        overflow-y: auto;
      }
    }
  }

  & {
    position: relative;
    min-width: 0;
  }

  label {
    position: static;
  }

  input[type="text"][aria-expanded="true"] {
    border-radius: $border-radius $border-radius 0 0;
    outline: none;
  }

  [role="combobox"] {
    width: $full-width;
    margin: 0;
    border-radius: $border-radius;
    z-index: 1;
    border: 1px solid $field-border-color;
  }
}

.search-list-wrap {
  * {
    color: $white;
  }

  .header {
    flex-shrink: 0;
    display: none;
    margin: 0;
    text-align: center;
    color: $white;
    background: $main;
    padding: 10px 5px;
    font-size: 1.1875rem;
    font-weight: bold;

    .close-button {
      float: left;
      display: inline-block;
    }

    .autocomplete-label {
      margin-left: -24px;
    }

    .glyphicons {
      color: $white;

      &:hover {
        background: none;
      }
    }
  }

  .inline[role="listbox"] {
    position: absolute;
  }

  .extra-field {
    color: grey;
    font-size: .7rem;

    .title {
      display: inline-block;
      margin-right: 1em;
      text-transform: uppercase;
    }

    .title-separator {
      display: none;
    }
  }

  @media #{$small} {
    display: none;

    &.active.breakout {
      display: flex;
      flex-direction: column;

      @include mixins.cover-fixed;

      z-index: $zindex-codepicker-mobile;
      background: $white;

      .header {
        display: block; // undo display: none
      }

      ul {
        flex-grow: 1;
        overflow-y: scroll;
      }
    }
  }
}

.listbox-container {
  flex-grow: 1;
  display: block;
  z-index: $zindex-context-selector;
  background: $autocomplete-background;
  border: 1px solid $autocomplete-border-color;
  border-radius: 0 0 .25rem .25rem;
  border-top-width: 0; // Touches above input, so don't double the border
  color: white;

  @media #{$medium} {
    position: absolute;
    width: calc(100% - 2.25rem);
    max-height: 400px;
    margin: 0 .125rem;
  }

  & {
    overflow: hidden auto;
  }

  button.load-more {
    width: 100%;
    margin: 0;
    padding-top: .5em 0;
    display: none;

    &[data-remaining="true"] {
      display: inline-block;
    }

  }
}

[role="listbox"] {
  &:not([aria-expanded="true"]) {
    display: none;
  }

  .zero-records {
    padding: 8px;
  }

  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: block;
  }

  [role="sectionHeader"] {
    font-weight: bold;
    background: $button;
    color: white;
  }

  li[role="option"] {
    min-width: 90px;
    padding: 8px;
    font-size: 0.875rem;
    transition: $autocomplete-transition;
    border-color: color.mix($autocomplete-border-color, white, 75%);
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &:last-child {
      border-bottom-width: 0;
    }

    &[aria-selected="true"],
    &:hover,
    &:focus {
      background: color.mix($autocomplete-border-color, black, 50%);
    }

    a {
      display: block;
      padding: 2px;
      text-decoration: none;
    }
  }
}


.form-selector {
  &.focused {
    @media #{$small} {
      background: $background-white;
    }
  }
}

[role="combobox"] {
  &[aria-expanded="true"] {
    border-color: $autocomplete-border-color;
  }
}

.form.barebones {
  [role="combobox"][aria-autocomplete="inline"] {
    border: 1px solid rgb(0 0 0 / 25%);
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
    color: #7a8187;

    &+button {
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
    }

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;

      &+button {
        border-bottom-right-radius: 0;
      }
    }
  }
}