/* ESS extra */
label {
    &.custom-file-label {
        font-size: inherit;
        font-weight: inherit;
        text-transform: inherit;
        color: inherit;
    }
}

/* Sticky labels */
.label-float {
    opacity: 0.65 !important;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !important;
}

.label-normal {
    opacity: 1 !important;
    transform: none !important;
}