@import './node_modules/sass-svg-uri/svg-uri';
@import 'theme';

/*
 *
 * Aurion UI — ESS
 *
 */

// Global
@import "../base/definitions";
@import "../base/global/mixins";

// Fonts
@import '../fonts/glyphicons/styles';
@import '../fonts/glyphicons-halflings/styles';

// Legacy
@import '../components/dotsloading/styles';
@import '../pages/custom-ess-barebones';

// Other components
@import './node_modules/croppie/croppie';
@import '../components/fullcalendar/extra';
@import "../base/global/utils";
@import "../base/global/animations";
@import "../base/global/layout";
@import "../base/global/navigation";
@import "../base/global/icons";

// Main SCSS (Previously from /less/ folder)
@import "../base/components/context-selector";
@import "../base/components/action-lists";
@import "../base/components/autocomplete";
@import "../base/components/formview";
@import "../base/components/modal";
@import "../base/components/notifications";
@import "../base/components/sheet";
@import "../base/components/progress-bar";
@import "../base/components/tabs";
@import "../base/components/cell";
@import "../base/components/staffschedule";
@import "../base/components/scrolly-page-selector";
@import "../base/components/loading";

// Page-based Overrides
@import "../base/screens/login";
@import "../base/screens/survey";
@import "../base/screens/personal";
@import "../base/screens/timekeeper";
@import "../base/screens/bpa";

// Include standalone files
@import "../base/screens/charting";
@import "../base/print-report";

/* Misc Details */
@import '../components/mixins/extra';
@import '../components/listgroup/extra';
@import '../components/label/extra';
@import '../components/flatpickr/extra';
@import '../components/logo/extra';

// Hack
@import '../pages/misc/hack';
