.actionable-list .cell {
  list-style: none;

  &:not(last-child) {
    border-bottom: 1px solid $border-color;
  }

  position: relative;
  background: rgb(252 252 252 / 100%);


  &[data-url]:not([data-url=""]) {
    &:hover {
      cursor: pointer;
      background: $border-color;
    }
  }

  &.selected {
    background: $border-color;
  }

  h1,
  h2 {
    padding: 0;
    font-weight: 400;
    margin: 0;
    background: transparent;
  }

  h1 {
    font-size: 1.5em;
    color: rgb(0 0 0 / 70%);
  }

  h2 {
    font-size: 1.3em;
    color: rgb(0 0 0 / 60%);
  }

  .detail {
    font-size: 1.1em;
    font-weight: 400;
    color: rgb(0 0 0 / 60%)
  }

  .date {
    font-size:0.8rem;
    font-weight: 400;
    color: rgb(0 0 0 / 60%);

    @media only screen and (width <= 767px) {
      position: inherit;
    }
  }

  .status {
    font-size:0.8rem;
    font-weight: 400;

    p {
      float: right;
      margin-right: 20px;
      top: 20px;
      font-size: 0.8em;

      @media only screen and (width <= 767px) {
        float: initial;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .add {
    text-align: center;
    color: rgb(0 0 0 / 80%);
  }

  .container {
    display: flex;

    @media only screen and (width <= 767px) {
      flex-direction: column;
    }
  }

  .left {
    flex: 1 0 auto;
  }

  .left-detail {
    color: rgb(0 0 0 / 60%);
    font-size: 1.1em;
    font-weight: 400;
  }

  .right {
    flex: 0 0 auto;
    text-align: right;

    @media only screen and (width <= 767px) {
      text-align: left;
    }
  }

  .right-date {
    color: rgb(0 0 0 / 60%);
    font-size: 0.8em;
    font-weight: 400;
    margin: 0.5em 1.5em 0 0;
    text-align: left;
    width: 6em;

    @media only screen and (width <= 767px) {
      float: none;
      margin: 0;
    }
  }

  .right-detail {
    color: rgb(0 0 0 / 60%);
    font-size: 0.8em;
    font-weight: 400;
  }

  .right-status {
    font-size: 0.8em;
    font-weight: 400;
    margin: 0.5em 1.5em 0 0;

    @media only screen and (width <= 767px) {
      float: none;
      margin: 0;
    }
  }
}