section {
  margin-top: 1.25rem;
  padding: 1.25rem;
  border: none;
  border-radius: 0.25rem;
  background-color: $background-white;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 7.5%) !important;

  &.continued {
    margin-top: 0;
  }

  section {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
  }
}

// Container Styles
.flex-wrap {
  display: flex !important;

  &.wrap {
    flex-wrap: wrap;
  }
}

@media #{$medium} {
  .flex-wrap-medium {
    display: flex;
  }
}

// Contained Styles
.flex-grow {
  flex-grow: 1;
}

.flex-center {
  align-self: center;
}

.flex-valign-center {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.medium-hide {
  @media #{$medium} {
    display: none;
  }
}

.section-accordian {
  .section-summary {
    position: relative;
    margin: 0;
    color: $typography-secondary-color-dark;
    cursor: pointer;
    padding: 10px 10px 10px 0;

    @media #{$medium} {
      padding: 20px 20px 20px 0;
    }

    &.open {
      .section-caret .glyphicons {
        transform: rotate(180deg);
      }
    }
  }

  [data-accordian-section]:not(:last-child) .section-summary {
    border-bottom: 1px solid $border-color;
  }

  .section-indicator {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0;
    border-right: $primary 2px solid;

    @media #{$medium} {
      padding: 10px 0;
    }

    @media only screen and (width >= 992px) {
      padding: 20px 0;
    }

    .glyphicons {
      width: 64px;
      text-align: center;

      @media #{$medium} {
        font-size: 48px;
        width: 100px;
      }

      @media only screen and (width >= 992px) {
        font-size: 64px;
        width: 120px;
      }
    }
  }

  .section-error-indicator {
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    font-size: .5rem;
    font-weight: bold;
    text-align: center;
    color: $error;

    @media #{$medium} {
      bottom: -12px;
      font-size: 0.625rem;
    }

    @media only screen and (width >= 992px) {
      bottom: -6px;
      font-size: .75rem;
    }
  }

  .section-summary-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;

    @media #{$medium} {
      margin-left: 10px;
      line-height: 48px;
      font-size: 24px;
    }

    @media only screen and (width >= 992px) {
      margin-left: 20px;
      line-height: 64px;
      font-size: 36px;
    }

    .count {
      font-size: 24px;
      display: inline-block;
      text-align: center;
      min-width: 40px;
      vertical-align: sub;
      margin-right: 0;

      @media #{$medium} {
        min-width: 90px;
        font-size: 48px;
        margin-right: 10px;
      }

      @media only screen and (width >= 992px) {
        min-width: 90px;
        font-size: 64px;
        margin-right: 20px;
      }
    }
  }

  .standard-list {
    // overflow-y: hidden; // <- ? This is resulting in autocomplete's dropdown being cropped
    padding: 0;
    margin-bottom: 0;
  }

  .section-entries {
    transition: max-height $transition-fast ease-in-out;
    max-height: 0;

    &[data-collapsible="false"] {
      max-height: none;
    }
  }

  .section-config {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: $main;

    .glyphicons {
      transition: transform $transition-default ease-in-out;
      font-size: 16px;

      @media #{$medium} {
        font-size: 24px;
      }
    }
  }

  .section-caret {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: $primary;

    &:hover,
    &:focus {
      background: transparent;
    }

    .glyphicons {
      transition: transform $transition-default ease-in-out;
      font-size: 16px;

      @media #{$medium} {
        font-size: 24px;
      }
    }
  }
}

.instruction {
  color: rgb(0 0 0 / 67%);
}

.help {
  position: absolute;
  top: 0;
  right: 0;
  color: $brand-info;

  .glyphicons {
    font-size: 1.5rem;
    color: $brand-info;
  }

  a {
    &:hover {
      .glyphicons {
        color: $brand-primary;
      }
    }
  }
}

header {
  position: relative;

  ul {
    list-style: none;

    &.help {
      position: absolute;
      top: 15px;
      right: -10px;
      margin: 0;
      padding: 0;
    }

    li {
      float: right;
      margin-right: 5px;
    }
  }
}

.header {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  .glyphicons {
    color: rgb(0 0 0 / 60%);
    color: $brand-info;
    font-size: 18px;
    padding: 0;
    transition: all $transition-slow cubic-bezier(0, 1, 0.5, 1);
    border-radius: 50%;
  }
}

// .action-items {
//   header {
//     h1 {
//       margin-bottom: 0;
//     }

//   }
// }
.standard-list {
  .actions {
    white-space: nowrap;

    // overflow-x: auto; // <- ? This is resulting in a vertical scrollbar appearing
    .button {
      display: inline-block;
    }
  }
}

.admin {
  display: flex;

  h2 {
    margin-top: -10px;
    padding: 10px;
  }

  .settings {
    display: inline-block;

    h1 {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  nav {
    display: inline-block;
    padding: 10px;
    margin-left: -10px;
    margin-top: -16px;
    margin-bottom: -10px;
    background-color: $primary-shade;
    min-width: 200px;

    ul {
      padding: 0;
      font-size: 16px;
      list-style: none;

      li {
        padding: 10px;

        a {
          color: $typography-primary-color-light;
        }
      }
    }
  }
}

.back-action-section {
  background: transparent;
  padding: 0;
  margin: 20px 0;

  a {
    @media only screen and (width <= 767px) {
      width: 100%;
    }
  }
}

.pagehead-control {
  button {
    @media only screen and (width <= 767px) {
      margin-top: 0.25em;
      width: 100%;
    }
  }
}

.action-items-container {
  border-top: 1px $shadow-color;
  border-radius: 0;
}

.section.tablelist[id="recruit/recommend-list"] {
  .actions {
    button {
      margin-right: 0.25em;

      @media only screen and (width <= 767px) {
        margin: 0.25em 0 0;
      }
    }
  }

  @media only screen and (width <= 767px) {
    .actions {
      & > * {
        order: 9;
      }

      [id="bulkupdate-set-all-recommendations"] {
        order: 1;
      }
    }
  }

  @media #{$medium} {
    button#RECOMMEND_COMPLETE,
    button#INTERVIEW_ATTENDANCE {
      // Push the Submit button out from the default Save/Reset buttons
      margin-left: 2em;
    }
  }
}

.text-right {
  text-align: right;
}

.text-sm-right {
  @media only screen and (width <= 767px) {
    text-align: right;
  }
}

.text-md-right {
  @media #{$medium} {
    text-align: right;
  }
}

.text-lg-right {
  @media #{$medium} {
    text-align: right;
  }
}