.glyphicons-bin-styling,
.glyphicons-cloud-upload-styling {
  height: auto;
  margin: 0;
  padding: 4px 10px;
}

.glyphicons-paperclip-styling {
  font-size: inherit;
  padding-left: 1em;
}

.glyphicons-paperclip-styling-paysummary {
  font-size: inherit;
  padding: 0.5em 0.5em 0.5em 1em;
}

.glyphicons-cogwheels-styling {
  padding-bottom: .25rem;
  padding-left: .125rem;
}

.glyphicons-ok-sign-styling {
  font-size: .875rem;
  font-weight: 400;
  top: .438rem;
}

.halflings-styling {
  top: .25rem;
}

.formview .form-input-button.glyphicons {
  min-width: 2.625rem;
}
