@use "../global/mixins";

/*
Chart Display Styles
*/
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: 50% 50%;
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: 50% 50%;
  }
}

.spin {
  animation-name: spin;
}

.animate {
  transition: all $transition-fast;
}

.hr-graphing {
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .hr-graph-wrapper {
    display: inline-block;
    vertical-align: top;
    min-height: $graph-min-height;
    position: relative;
    padding: 0 25px;
  }
}

.chartable {
  position: relative;
}

.bpa-report .tooltip {
  transition: opacity $transition-default ease-in-out;
  opacity: 0;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 12px;
  border-radius: $border-radius;
  width: 150px;
}

.hr-stats-header {
  margin: 0;

  h1 {
    margin: 0;
    margin-bottom: 0.5188rem;
  }
}

.hr-graphing-header {
  display: flex;

  h2 {
    flex: 2 auto;
    margin: 0;
    line-height: 36px;
    background: transparent;
    text-transform: none;
  }
}

.hr-graphing-group {
  position: relative;
  min-height: $graph-min-height;

  &.loading::after {
    position: absolute;
    display: block;
    height: 64px;
    width: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .chart-placeholder {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
}

.bar,
.arc,
.line-anchor {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.bar {
  shape-rendering: crispEdges;
}

.line-anchor {
  fill: $main;
  stroke: $main;
}

path.area {
  cursor: pointer;
}

.label-group text,
.tick text {
  pointer-events: none;
}

path.line {
  fill: none;
  cursor: pointer;
  stroke-width: 2px;
}

g.axis {
  line,
  path {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
}

.highlight {
  fill: red;
  stroke: red;
}

.legends {
  padding: 10px;
  margin: 0;
  margin-bottom: -10px;
  background: $main;

  h3 {
    margin: 0 0 12px;
    padding: 0;
    font-size: 0.625rem;
    background: transparent;
  }
}

.legend-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.legend {
  display: block;
  margin-bottom: 12px;
  vertical-align: top;
  flex: 1 1 20%;
}

.legend-chit {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  float: left;
}

.legend-text {
  color: $typography-primary-color-light;
  font-size: .5rem;
  line-height: 16px;
  padding-right: 6px;
  text-transform: capitalize;
}

.stack-label {
  fill: #fff;
  font-size: 12px;
  font-weight: bold;

  &.behind {
    fill: $main;
  }
}

.column {
  .x.axis .tick text {
    display: none;
  }
}

.bar {
  .y.axis.tick text {
    display: none;
  }
}

/* HR Stats Page Styles */

.hr-graph {
  position: relative;
  overflow: hidden;
}

.hr-graphing-config-group {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  transition: transform $transition-default ease-in-out;
  transform: translateX(101%);
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  border-left: 5px solid $secondary;
  z-index: 20;

  &.visible {
    transform: translateX(0);
  }
}

/* HR Stats Config Form Styles */
.chart-data-config {
  h3 {
    padding: 12px 20px;
    margin: 0 -20px 12px;
  }

  .combobox label {
    width: 100%;
  }

  select {
    width: 100%;
  }

  .control.radio {
    width: 45%;
    display: inline-block;
    padding: 0;
  }
}

.carousel-wrapper.animate {
  transition: transform $transition-default ease-in-out;
}

.carousel-controls {
  padding-right: 5rem;
  width: 100%;
}

button.carousel-control {
  position: absolute;
  width: 36px;
  height: 60px;
  top: 50%;
  padding: 4px;
  transform: translateY(-50%);
  border: none;
  background: none;

  span {
    font-size: 2.5rem;
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  &.prev {
    left: -10px;
  }

  &.next {
    right: -10px;
  }

  .glyphicons {
    transition: all $transition-default ease-in-out;
    color: $secondary;

    &:active,
    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.hr-graphing-content {
  margin-left: -20px;
  margin-right: -20px;
}

// Pad the chart to fit the buttons
.hr-graphing-chart {
  margin: 0 36px;
  min-height: calc($graph-min-height * 0.9);
  bottom: 0;

  @media only screen and (width <= 767px) {
    margin: 0;
  }
}

.chart-colour-1 {
  fill: $chart-colour-1;
  stroke: $chart-colour-1;
  background: $chart-colour-1;
}

.chart-colour-2 {
  fill: $chart-colour-2;
  stroke: $chart-colour-2;
  background: $chart-colour-2;
}

.chart-colour-3 {
  fill: $chart-colour-3;
  stroke: $chart-colour-3;
  background: $chart-colour-3;
}

.chart-colour-4 {
  fill: $chart-colour-4;
  stroke: $chart-colour-4;
  background: $chart-colour-4;
}

.chart-colour-5 {
  fill: $chart-colour-5;
  stroke: $chart-colour-5;
  background: $chart-colour-5;
}

.chart-colour-6 {
  fill: $chart-colour-6;
  stroke: $chart-colour-6;
  background: $chart-colour-6;
}

.chart-colour-7 {
  fill: $chart-colour-7;
  stroke: $chart-colour-7;
  background: $chart-colour-7;
}

.chart-colour-8 {
  fill: $chart-colour-8;
  stroke: $chart-colour-8;
  background: $chart-colour-8;
}

.chart-colour-9 {
  fill: $chart-colour-9;
  stroke: $chart-colour-9;
  background: $chart-colour-9;
}

.chart-colour-10 {
  fill: $chart-colour-10;
  stroke: $chart-colour-10;
  background: $chart-colour-10;
}

.chart-colour-11 {
  fill: $chart-colour-11;
  stroke: $chart-colour-11;
  background: $chart-colour-11;
}

.chart-colour-12 {
  fill: $chart-colour-12;
  stroke: $chart-colour-12;
  background: $chart-colour-12;
}