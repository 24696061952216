@use "../global/mixins";

.sheet-overlay {
  background: $sheet-overlay-background;

  @include mixins.cover-fixed;
}

.sheet {
  position: fixed;
  border-radius: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  transition: transform $transition-slow cubic-bezier(0, 1, 0.5, 1);
  display: block;
  background: $sheet-background;
  border: none;
  font-size: 1.5rem;

  @media #{$medium} {
    width: auto;
    position: absolute;
    top: 0;
    bottom: initial;
    right: 60px;
    left: auto;
    height: 100%;
    border-top: none;
    border-left: 1px solid $sheet-background;
    display: flex;
    background: $sheet-background;
  }

  .actionable {
    display: block;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    min-width: 80px;
    background: $sheet-background;

    .glyphicons {
      line-height: inherit;
      display: none;

      @media #{$medium} {
        display: block;
      }
    }

    @media #{$medium} {
      padding: 0;
      min-width: 100px;
    }

    &.cancel {
      @media #{$medium} {
        display: none;
      }
    }

    &:hover {
      font-weight: bold;
    }

    span {
      @media #{$medium} {
        display: block;
        font-size: .75rem;
      }
    }
  }
}
