body.login {
  background-color: $primary;

  .c {
    margin: 7.5px;
    padding: 20px;
    background: #fff;
    position: relative;
  }

  #logo {
    display: inline-block;
    width: 100%;
    min-height: 60px;
    max-width: 240px;
    max-height: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.formview-sm {
  // small (thin) view

  .form-item {
    font-size: 1rem;
    margin-bottom: 10px;
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  .form-label {
    text-align: left;
    margin-right: 0;
  }
}

.login .formview-sm {
  .form-label,
  input,
  textarea {
    font-size: 1rem;
  }
}