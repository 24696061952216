/* ESS CSS to be Loaded Last */

body {
  overflow-x: inherit !important;
  -webkit-overflow-scrolling: auto !important;
}

a:hover {
  text-decoration: none;
}

.table-clickable-row {
  &:hover {
    td {
      &.document {
        a {
          color: white;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

.bg-info.alert-info {
  .col-form-label,
  .form-control-plaintext {
    color: white !important;
  }
}

.bg-warning.alert-warning {
  .col-form-label,
  .form-control-plaintext {
    color: white !important;
  }
}

.bg-danger.alert-danger {
  .col-form-label,
  .form-control-plaintext {
    color: white !important;
  }
}

.nav-tabs .nav-link[aria-selected="true"] {
  color: $gray-800;
  background-color: white;
  border-color: $gray-300 $gray-300 white;
}
