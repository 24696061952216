#context-search-bar {
  display: none;

  @media #{$medium} {
    display: flex; // restore .flex-wrap
  }

  .context-label {
    font-size: 0.8125rem;
  }

  .photo {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 8px;
  }

  .name,
  .empno,
  .job-title {
    opacity: 0.9;
  }

  .name {
    font-weight: bold;
  }

  .empno {
    margin-left: 8px;
  }

  .job-title {
    display: block; // Force onto its own line
  }


  .listbox-container {
    max-height: 600px;
    overflow-y: auto;
    z-index: $zindex-context-selector;

    @media #{media-medium-only} {
      // if the user has squished the window up allow the list to expand past width: 100%
      position: absolute;
      right: 0;
      width: 500px;
      border-top-width: 1px; // restore top border
      margin-top: -1px; // and overlay the top border with field's bottom border
    }

    [role="option"] {
      margin: 2px 3px;
      padding: 1px 1px 1px 5px;
    }
  }
}

#aurion-context-select-modal {
  // Override aurion-ui-ess-barebones.min.css
  // - Prevent .disabled boxes from appearing like clickable links
  .list-group-item-link.disabled:focus,
  .list-group-item-link.disabled:hover {
    background-color: transparent;
    color: black;
    cursor: auto;
  }
}