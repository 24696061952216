@use "sass:color";

/* =======================================
=            Extra                      =
======================================= */

 $bootstrap-root-em-fix:  1.6rem;


/* =======================================
=            Colour Palettes            =
======================================= */

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Reference Colours
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


// Working Notes from Martin (and todo list)
// - Only allow the customer to change the logo and the background colour behind the logo.
//   - Don't allow the customer to override the primary colour
//   - instead of setting "$primary" the customer colour should be stored in $customer-colour-1
//   - change settings page to set "customer-colour-1" => $main/$navigation
// - Get rid of the secondary colour (on the theme settings page), and just default to a gray
// - Borders on fields should not be blue.  make them grayscale.
// - There is only one primary colour, the the other colours are "status/flag" colours.
// - Reference Colours
//   http://aurjira.hrs.com:7990/projects/AUX/repos/aurion-ui/browse/src/scss/_colors.scss
//   http://aurconfluence.hrs.com/display/WD/Aurion+Colour+Palette

$brand-primary: #387bbd; // todo: change to "$primary"
$gray:    #7a8187;

$brand-success: #6cc644;
$brand-info:    #5bc0de;
$brand-warning: #f93;
$brand-danger:  #bd2c00;

$primary-darkest:  color.mix($brand-primary, black, 25%);
$primary-darker:   color.mix($brand-primary, black, 50%);
$primary-dark:     color.mix($brand-primary, black, 75%);
$primary-light:    color.mix($brand-primary, white, 75%);
$primary-lighter:  color.mix($brand-primary, white, 50%);
$primary-lightest: color.mix($brand-primary, white, 25%);

$gray-darkest:  color.mix($gray, black, 25%);
$gray-darker:   color.mix($gray, black, 50%);
$gray-dark:     color.mix($gray, black, 75%);
$gray-light:    color.mix($gray, white, 75%);
$gray-lighter:  color.mix($gray, white, 50%);
$gray-lightest: color.mix($gray, white, 25%);



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Customer Overridable Settings
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$logo:                  '../img/logo-aurion-white.png';
$primary:               $brand-primary; // TODO: don't allow customer to override
$secondary:             $gray-light;    // TODO: don't allow customer to override
$tertiary:              $secondary;
$typography-color:      #fff;




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Derived Colours
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$accent-color: $secondary;
$main: color.adjust($primary, $lightness: -10%);
$main-shade: color.adjust($main, $lightness: -20%);
$main-tint: color.adjust($main, $lightness: 20%);


// PRIMARY COLOR
$primary-shade: color.adjust($primary, $lightness: -10%);
$primary-tint: color.adjust($primary, $lightness: 20%);

// SECONDARY COLOR
$secondary-shade: color.adjust($secondary, $lightness: -20%);
$secondary-tint: color.adjust($secondary, $lightness: 20%);

// TERTIARY COLOR
$tertiary-shade: color.adjust($tertiary, $lightness: -10%);
$tertiary-tint: color.adjust($tertiary, $lightness: 20%);

// QUARTERNARY COLOR
$quarternary: #d0d8e0;
$quarternary-shade: color.adjust($quarternary, $lightness: -10%);
$quarternary-tint: color.adjust($quarternary, $lightness: 5%);

// SURVEY AND CALENDAR COLORS
$extra-color: #4682B4;
$extra-color-shade: color.adjust($extra-color, $lightness: -10%);
$extra-color-tint: color.adjust($extra-color, $lightness: 20%);

// GLOBAL COLORS
$white: #fff;
$border-color: $primary-lightest;
$background-color: #DEE0E1;
$error: $brand-danger;
$warning: $brand-warning;
$mandatory: $brand-danger;
$background-white: $white;
$main-border: #dedede;
$menu-down: color.mix($brand-primary, black, 75%);
$modal-overlay:#000;
$background-black: #000;
$shadow-color: #dedede;

// TYPOGRAPHIC COLOURS
$typography-primary-color-dark: #9ba1a5;
$typography-secondary-color-dark: #686868;
$typography-primary-color-light: #FFF;
$typography-secondary-color-light: #7a8187;
$typography-black: #000;

// BUTTONS
$button: #7a8187;
$button-primary: $brand-primary;
$button-success: $brand-success;
$button-info: $brand-info;
$button-danger: $brand-danger;

@mixin button-color($font-color, $bg-colour) {
    background-color: $bg-colour;
    color: $font-color;

    &:hover,
    &:focus {
        background-color: color.adjust($bg-colour, $lightness: -10%);
    }

    &:disabled:not(.btn) {
        background-color: color.adjust($background-color, $lightness: -10%);
        cursor: not-allowed;
        color: $font-color;

        &:hover,
        &:focus {
            background-color: color.adjust($background-color, $lightness: -10%);
        }
    }
}

/* -----  End of Colour Palettes  ------ */



/* ========================================
=            Global Variables            =
======================================== */

// GLOBAL VARIABLES
$border-radius: 0;
$section-border-radius: 0;
$padding: .75rem 1.25rem;
$max-width: 1140px;
$min-padding: 5px;
$min-margin: 5px;
$margin: 10px;
$margin-between: 20px;
$no-margin: 0;
$no-padding: 0;
$no-border: 0;
$standard-shadow: 0 0 0 $border-color;

// TRANSITIONS
$transition-default: 300ms;
$transition-fast: 100ms;
$transition-slow: 1000ms;
$transition-slowest: 2000ms;

// WIDTH
$full-width: 100%;

// Typography;
$base-font-family: "Open Sans", Tahoma, Geneva, Helvetica, Arial, sans-serif;
$base-font-size: 10px;
$base-font-size-medium: 10px;
$base-font-size-small: 10px;
$base-line-height: 1.5 * $base-font-size;

$default-font-size: 1rem;
$paragraph-font-size: 0.875rem;
$h1-font-size: 1.5rem;
$h2-font-size: 1.1875rem;
$h3-font-size: 1rem;

// BORDERS AND CHROME
$standard-border: 1px solid $border-color;
$standard-box-shadow: 0 0 10px $shadow-color;
$faint-border: 1px solid difference($background-white, #202020);


/* -----  End of Global Variables  ------ */

/* =============================================
=            Component Definitions            =
============================================= */

// BUTTONS
$button-padding: 4px 20px;
$button-border: $no-border;
$button-font-size: 1rem;
$button-border-radius: $border-radius;
$button-margin: 0.25rem;
$button-outline: none;
$button-transition: color $transition-default linear,
    background-color $transition-default ease-in-out,
    border-color .25s ease-in-out;


// TABLES
$table-margin: 0;
$table-width: $full-width;
$table-font-size: 0.875rem;
$table-border-radius: $border-radius;
$table-row-background-even: #f9f9f9;
$table-row-background-selected: $border-color;
$table-header-background: $shadow-color;
$table-header-color: rgb(0 0 0 / 60%);

// Forms
$field-height: 42px; // 1px border + 10px padding + 22px inner hight (font-size=16px=22px height) => 42
$field-border-color: $gray;

// SHEET
$sheet-overlay-background: rgb(0 0 0 / 20%);
$sheet-background: #fff;

// MODAL
$modal-margin: 3%;
$modal-max-height: 100% - 2 * $modal-margin;
$modal-max-width: $max-width - 64px;

// PROGRESS BAR
$progress-width: 100%;
$progress-height: 8px;
$progress-color: fade($typography-color, 33%);
$progress-background: transparent;

// Notifications
$notification-background: #EEE;
$notification-color: rgb(0 0 0 / 80%);
$notification-text-shadow: #e0e0e0 1px 1px 0;

// Autocomplete
$autocomplete-transition: background-color $transition-default;
$autocomplete-background: $brand-primary;
$autocomplete-border-color: $brand-primary;

// Context selector
$context-selector-background: #686868;
$context-selector-header-background: $main;
$context-selector-header-color: #AFAFAF;
$context-option-hover-color: rgb(255 255 255 / 25%);

// User Photo P-Deets
$user-photo-border-color: #ccc;

// Survey
$survey-panel-background: #f5f5f5;
$survey-panel-border: 1px solid #e3e3e3;
$survey-panel-border-radius: 0;
$survey-panel-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
$saveandnext-color: rgb(153 180 51);
$cancel-color: rgb(238 17 17);
$return-color: rgb(227 162 26);
$submit-color: rgb(0 163 0);
$hero-border-email-tint: rgb(45 137 239 / 95%);
$hero-background-email-tint: rgb(45 137 239 / 75%);
$hero-border-alert-tint: rgb(216 24 56 / 95%);
$hero-background-alert-tint: rgb(216 24 56 / 75%);
$survey-transition: all 1s cubic-bezier(0, 1, 0.5, 1);
$survey-action-background-color: #563d7c;
$survey-action-color: #767676;
$survey-action-border: 1px solid #e1e1e8;
$survey-error-border: 2px solid $secondary-shade;

// Tabpanels
$tabpanel-border-radius: $section-border-radius;
$tab-background: #f0f0f0;

/* Chart Min Height */
$graph-min-height:500px;

/* Chart Colours */
$chart-colour-1: #A5524D;
$chart-colour-2: #677F37;
$chart-colour-3: #E2BC4B;
$chart-colour-4: #B76929;
$chart-colour-5: #8C688F;
$chart-colour-6: #7D3223;
$chart-colour-7: #94AEA3;
$chart-colour-8: #406991;
$chart-colour-9: #A5524D;
$chart-colour-10: #677F37;
$chart-colour-11: #E2BC4B;
$chart-colour-12: #B76929;

/* -----  End of Component Definitions  ------ */



/* =========================================================
=            Responsive Breakpoint Definitions            =
========================================================= */

$large:  "only screen and (width >=992px)";
$medium: "only screen and (width >=768px)";
$small:  "only screen and (width <=767px)"; // autocomplete.js uses $small, so if updated remember to update autocomplete.js

$media-medium-only: "only screen and (width >= 576px) and (width <= 769px)";

/* -----  End of Responsive Breakpoint Definitions  ------ */



/* =========================================================
=                 Layering Definitions                    =
========================================================= */

$zindex-sweet-alert:                99999;
$zindex-notification-visible:       9999;
$zindex-fullscreen-loading-mask:    9998;
$zindex-modal:                      2600;
$zindex-codepicker-mobile:          2600;
$zindex-datepicker:                 2600;
$zindex-menu-expanded:              2500;
$zindex-staff-schedule:             2500;
$zindex-app-header:                 2400;
$zindex-context-selector:           100;
$zindex-personal-photo:             1;
$zindex-progress:                   2700;

.zindex-staff-schedule { z-index: $zindex-staff-schedule; }

/* -----  End of Layering Definitions  ------ */

.a-fixed-fullscreen { 
    position: fixed;
    inset: 0;
}

.a-box-shadow-3 {
  box-shadow: 3px 3px 4px #8888;
  margin-bottom: 5px;
  margin-right: 5px;
}

.a-user-select-none { user-select: none; }
