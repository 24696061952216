#bpa-reports {
  #bpa-report-xml-result-list,
  #panel-chart {
    h1 {
      background: none;
      color: $secondary;
    }

    header {
      display: flex;

      h1 {
        flex: 1 0 auto;
        margin-top: 0;
      }

      #chart-type-selector {
        flex: 0 1 auto;
      }
    }
  }
}

.chart-type-selectors {
  margin-bottom: 1em;
  text-align: center;
}

[data-pie-series-selector] {
  margin-bottom: 1em;

  .formview .form-label {
    text-align: left;
  }
}