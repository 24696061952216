select
textarea {
  background-image: none !important;
}

.list-group-checkbox {
  .list-group-item {
    &::before {
      top: .5rem;
      font-family: "Glyphicons Regular", sans-serif;
      content: "\e191";
    }
  }

  .list-group-item-checked {
    &::before {
      content: "\e194";
    }
  }
}

.list-group-item-more {
  &::after {
    font-size: $font-size-lg;
    font-family: "Glyphicons Regular", sans-serif;
    content: "\e224";
  }
}

.list-group-item-back {
  &::before {
    font-size: $font-size-lg;
    font-family: "Glyphicons Regular", sans-serif;
    content: "\e225";
  }
}

label {
  &:not(.btn),
  &.form-label {
    font-size: $font-size-sm;
    font-weight: 400;
    text-transform: uppercase;
    color: $gray-400;
  }

  &.form-check-label {
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
  }
}

.checkbox,
.radio {
  label {
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
  }
}

.form-control,
.form-input,
.form-select {
  &.form-control-danger {
    padding-left: (($input-padding-x + .0625rem) - .25rem) !important;
    border-left-color: $brand-danger !important;
    border-left-width: .25rem !important;

    &:active,
    &:focus {
      padding-left: $input-padding-x !important;
      border-left-color: $input-focus-border-color !important;
      border-left-width: $input-border-width !important;
    }
  }
}

.form-check {
  &.required {
    border-left: .25rem solid $brand-danger;
    padding-left: 1.75rem;
  }
}

button:not(.btn) {
  &.close {
    margin: 0;
    padding: 0;
    background-color: transparent;
    line-height: 1;
    color: rgb(0 0 0 / 80%);
    text-shadow: none;

    &:hover {
      opacity: .6;
    }
  }
}

.modal-header {
  button:not(.btn) {
    &.close {
      margin: -.5rem .75rem -1rem auto;
    }
  }
}

.img-avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.table {
  th {
    text-align: left;
  }

  td {
    text-align: left;
  }
}

table.dataTable thead {
  > tr > {
    .sorting::after,
    .sorting_asc::after,
    .sorting_desc::after,
    .sorting_asc_disabled::after,
    .sorting_desc_disabled::after {
      right: 5px;
      bottom: 5px;
      font-family: "Glyphicons Regular", sans-serif;
      opacity: .8;
    }

    .sorting::after {
      opacity: .2;
      content: "\e404";

      /* sort */
    }

    .sorting_asc::after {
      content: "\e410";

      /* sort-by-attributes */
    }

    .sorting_desc::after {
      content: "\e409";

      /* sort-by-attributes-alt */
    }
  }
}

.dt-length,
.dt-search,
.dt-info,
.dt-paging {
  margin-top: 8px;
  margin-bottom: 8px;
  color: $gray-light;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_desc_disabled::before {
  content: none !important;
}

table.dataTable.table-sm .sorting::after,
table.dataTable.table-sm .sorting_asc::after,
table.dataTable.table-sm .sorting_desc::after {
  top: 4px;
}



// ESS table colors
.table-success {
  background-color: rgba($brand-success,.8);

  > th {
    border-color: white;
    background-color: rgba($brand-success,.8);
  }

  > td {
    border-color: white;
    background-color: rgba($brand-success,.8);
  }
}

.table-danger {
  background-color: rgba($brand-danger,.8);
  color: white;

  > th {
    border-color: white;
    background-color: rgba($brand-danger,.8);
    color: white;
  }

  > td {
    border-color: white;
    background-color: rgba($brand-danger,.8);
    color: white;
  }
}

th.table-danger {
  border-color: white;
  background-color: rgba($brand-danger,.8);
  color: white;
}

td.table-danger {
  border-color: white;
  background-color: rgba($brand-danger,.8);
  color: white;
}


// ESS Timekeeper Weekly
.table-timekeeper-weekly {
  background-color: white;

  &.table-bordered {
    border: .5rem solid $gray-lighter;
  }

  &.table {
    thead {
      th {
        span {
          text-transform: capitalize;
          color: rgb(255 255 255 / 40%);
        }
      }

      th[scope="col"] {
        background-color: $gray-lighter;
        color: $gray;
      }

      th[scope="row"] {
        background-color: $gray-lighter;
      }

      th[scope="rowgroup"] {
        background-color: $gray-lighter;
        border-right: .5rem solid $gray-lighter;
        color: $gray;
        text-transform: none;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        vertical-align: top;
      }
    }

    tbody {
      th {
        background-color: rgb(0 0 0 / 10%);

        .text-muted {
          color:rgb(0 0 0 / 10%);
        }
      }

      th[scope="rowgroup"] {
        background-color: rgb(0 0 0 / 10%);
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="row"] {
        background-color: $gray-lighter;
        color: $gray;

        strong {
          font-size: $font-size-base;
          color: $gray-dark;
        }
      }

      td[scope="colgroup"] {
        background-color: rgb(0 0 0 / 10%);
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        color: white;
      }
    }

    tfoot {
      border-top: .5rem solid $gray-lighter;

      th {
        background-color: rgb(0 0 0 / 10%);
      }

      th[scope="row"] {
        background-color: $gray-lighter;
        color: $gray-lightest;
      }

      th[scope="col"] {
        background-color: $gray-light;
      }

      th[scope="rowgroup"] {
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        color: white;
      }

      td[scope="colgroup"] {
        border-right: .5rem solid $gray-lighter;
      }

      tr.table-success {
        > td[scope="colgroup"] {
          background-color: rgba($brand-success,.2);
        }
      }

      tr.table-danger {
        > td[scope="colgroup"] {
          background-color: rgba($brand-danger,.2);
        }
      }
    }
  }
}

// ESS Leave Table
.table-ess-leave {
  thead {
    th {
      border: 0;
      padding: .5rem;
      font-size: $font-size-sm;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: .25rem;
        font-size: $font-size-xs;
      }
    }
  }

  tbody {
    td {
      padding: .5rem;
      border-color: white;
      text-align: right;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: .25rem;
        font-size: $font-size-sm;
        line-height: inherit;
      }
    }
  }

  .label {
    font-size: $font-size-lg;
    line-height: 1.1;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: $font-size-sm;
      line-height: inherit;
    }
  }

  .value {
    width: 5rem;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: $font-size-sm;
    }
  }

  .symbol {
    width: 2rem;
    padding: 0 .125rem;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    color: $gray;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 1.5rem;
      line-height: 1.1;
    }
  }

  .table-success {
    background-color: rgba($brand-success, .8) !important;
  }

  .table-info {
    background-color: rgba($brand-info, .8) !important;
  }

  .table-warning {
    background-color: rgba($brand-warning, .8) !important;
  }

  .table-danger {
    background-color: rgba($brand-danger, .8) !important;
  }

  .table-inverse {
    background-color: rgba($gray, .8) !important;
  }

  .table-success,
  .table-info,
  .table-warning,
  .table-danger,
  .table-inverse {
    color: white;
  }
}
