#user {
  position: relative;
  width: auto;

  dfn p {
    margin: 0 0 3px;
  }

  .pic {
    position: absolute;
    right: 0;
    margin: 110px 12px 0 0;
    overflow: hidden;
    z-index: $zindex-personal-photo; // appear above the 100% fields to the left so that onMouseOver event handler works

    .actions {
      position: absolute;
      bottom: -100px;
      right: 0;
      height: auto;
      width: 100%;
      padding: 4px 10px 0;
      transition: all 0.4s ease-in-out;
    }

    &:hover,
    &.fake-hover {
      .actions {
        background: $white;
        opacity: 0.8;
        bottom: 0;
      }
    }

    a {
      display: block;
      margin: 0 0 5px;
    }

    @media only screen and (width <= 767px) {
      position: relative;
      display: block;
      width: 150px;
      margin: 36px auto;
    }

    img {
      padding: 7px;
      background: $white;
      border: 1px solid $user-photo-border-color;
      display: block;
      box-shadow: 0 0 4px $shadow-color;
      border-radius: 5px;
    }

    .data {
      position: relative;
      left: 0;
      right: 0;
      display: block;
      overflow: hidden;
    }
  }

  .change {
    width: 100%;
    padding: 4px;
    border: 0;
    border-radius: $border-radius;
    background-color: $tertiary;
    color: $white;
    font-size: 0.875rem;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
    outline: none;
    transition: color 0.25s linear,
      background-color 0.25s ease-in-out,
      border-color 0.25s ease-in-out;
  }

  .delete {
    width: 100%;
    padding: 4px;
    border: 0;
    border-radius: $border-radius;
    background-color: $button-danger;
    color: $white;
    font-size: 0.875rem;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
    outline: none;
    transition: color 0.25s linear,
      background-color 0.25s ease-in-out,
      border-color 0.25s ease-in-out;
  }
}