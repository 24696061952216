@use "sass:color";

.formview {
  min-height: 32px;

  .section {
    margin-bottom: 0;
    box-shadow: none;
    border-top: none;
    border-bottom: none;
  }

  .form-item {
    margin: 8px 0;
    font-size: 0.875rem;
    position: relative;

    @media #{$medium} {
      display: flex;
      align-items: flex-start; // prevent input field from expanding vertically for multiline labels
      margin-left: 8px;
      margin-right: 8px;

      & > :nth-child(1) {
        // the label wrap
        flex: 0 0 176px;
      }

      .form-input-wrap {
        flex: 1;
      }
    }
  }

  .form-item-document {
    align-items: initial; // default back to stretch
  }

  .form-label {
    display: block;
    word-wrap: break-word;

    @media #{$medium} {
      margin-top: 11px; // align with input field's text (1px border + 10x padding)
      margin-right: 26px;
      text-align: right;
    }
  }


  @media #{$medium} {
    .form-item>.form-label {
      margin-right: 0;
      padding-right: 26px;
    }
  }

  div.form-input {
    padding: 10px;
  }

  .form-input {
    border: 1px solid $field-border-color;
    min-height: 42px;
    font-style: normal;
    word-wrap: break-word;
    display: block;
    flex: 0 1 auto;
    font-size: 0.875rem;

    &[readonly] {
      border-color: rgb(0 0 0 / 10%);
      color: rgb(0 0 0 / 60%);
    }

    &.mandatory {
      border-left: 6px solid $mandatory;
    }
  }

  .form-input-rawtext {
    border: $faint-border;
  }

  .form-input-button {
    margin: 0;
    padding: 4px 10px;
    margin-left: 1px;

    &[disabled] {
      background-color: color.adjust($background-color, $lightness: -10%);
      cursor: not-allowed;
      color: $typography-primary-color-dark;
    }

    &.glyphicons {
      top: 0;
      font-size: 1.125rem;
      width: 2.625rem; // make the button square (height should be 42)
    }
  }

  .form-input-wrap>.error,
  .form-input-wrap>.instruction {
    margin-top: 5px;
  }

  .form-input-wrap,
  .form-input-hwrap,
  .form-input {
    flex-grow: 1;
  }

  .form-input-hwrap {
    display: flex;
  }

  .form-item-document div.form-input {
    overflow: hidden;
    white-space: nowrap;
  }


  div.form-input.error {
    color: $error;
    border: none;
  }

  .form-item-radio {
    .radio-wrap {
      flex-grow: 1;
      border: 1px solid $field-border-color;

      .form-input {
        min-height: auto;
      }
    }
  }


  .notice {
    border: 1px solid #fedeb6;
    padding: 10px;
    background: rgb(254 222 182 / 50%);
    color: rgb(0 0 0 / 80%);
  }

  .checkbox-wrap {
    padding: 10px;
    position: relative;

    [type="checkbox"] {
      vertical-align: bottom;
      margin-bottom: 2px;
    }
  }

  .radio-wrap {
    padding: 15px;

    &.form-control-danger {
      border-color: $brand-danger;
      border-left-width: .25rem;
    }
  }

  select.form-input {
    width: 100%;
    padding: 10px;
    height: 42px;
  }

  .input-group {
    @media #{$medium} {
      display: flex;

      .form-input {
        flex: 0 1 auto;
        margin: 0.25rem;
      }

      .button {
        flex: 0 1 auto;
        margin: 0.25rem;
      }
    }

    @media only screen and (width <= 767px) {
      .button {
        width: 100%;
        margin: 10px 0;
        height: 38px;
      }
    }
  }

  // Alternative read only view of form
  .form-item {
    & > span.form-label {
      margin-top: 0;
    }
  }

  .field-wrap {
    position: relative;

    &.attachment {
      width: 100%;
    }
  }

  .autocomplete-code {
    opacity: .5;
    font-size: .75em;
  }
}

.form-instruction {
  section.action-items > &,
  // eg: <h1>Payroll</h1> on /payroll
  section.tablelist > & {
    // eg: <h1>Payroll/Net Pay</h1> on /payroll
    margin: 20px 0;
  }

  section.formview > & {
    // when inside an edit form
    margin: 16px 0; // same the H1 margin above
  }

  section.formview div.header > & {
    // when subsection inside an edit form
    margin: 20px 0; // same has header margin above
  }
}

dfn.form-rawtext {
  display: block;
  flex: 0 1 auto;
}

input.form-control-datepicker.form-control-danger:not(.flatpickr-input),
input.form-control-monthpicker.form-control-danger:not(.flatpickr-input) {
  // Setting a background image in mobile mode (in safari) on the native date field
  // is rendering the field as a normal field (instead of the native input field).
  background-image: none !important;
  padding-right: .75rem;
}


.form.barebones {
  @media #{$medium} {
    .form-control-label {
      margin-top: 12px;
    }

    .form-group.select select:not([multiple]) {
      margin-top: 8px;
    }
  }

  .form-group.select select[multiple] {
    padding: 0 .5rem .5rem 0;
    border: 1px solid rgb(0 0 0 / 25%);
    border-radius: .25rem;

    option {
      padding: .5rem .75rem;
    }
  }

  .form-group.document {
    .form-input {
      border: 1px solid rgb(0 0 0 / 25%);
      border-radius: .25rem 0 0 .25rem;
      padding: 9px;
    }

    .form-input-button {
      width: 2.625rem;
      height: 2.625rem;
      margin: -1px 0 1px 1px;
      padding: 0;

      &.upload {
        border-radius: 0 .25rem .25rem 0;
      }
    }
  }

  .form-group.rawtext {
    .form-control {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .form-group {
    .form-control.no-border {
      border-color: transparent;
      box-shadow: none;
      padding-top: 10px;
      padding-bottom: 10px;

      &.align-left {
        padding-left: 0;
      }
    }
  }
}
