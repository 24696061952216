#staffscheduleCt {
  background: #DEE0E1;
}

@media only screen and (width <= 767px) {
  #staffscheduleCt .fc-toolbar .fc-center {
    display: none;
  }
}

#staffscheduleCt .fc-cell-hidden .fc-title-label {
  visibility: hidden;
}

#staffscheduleCt .fc-text-hidden .fc-title-text {
  visibility: hidden;
}

#staffscheduleCt .fc-resource-area .fc-cell-content {
  white-space: normal;
  overflow-y: hidden;
}

#staffscheduleCt .fc-resource-area .fc-res-name {
  display: block;
  font-weight: bold;
}

#staffscheduleCt .fc-title-label,
#staffscheduleCt .fc-title-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

#staffscheduleCt #events-info-container {
  padding: 0.5rem 0 0;
  white-space: nowrap;
  overflow-x: auto;
}

#staffscheduleCt #events-info-container:not(:last-child) {
  margin-right: 0.5rem;
}

#staffscheduleCt .events-info-cell {
  cursor: pointer;
  vertical-align: top;
  min-width: 15rem;
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0.725rem 0.5rem;
  background: white;
}

#staffscheduleCt .events-info-cell:hover {
  background: $brand-primary;
  color: white;
}

#staffscheduleCt .events-info-cell h6 {
  font-size: 12px;
  line-height: 12px;
}

#staffscheduleCt .events-info-cell .date {
  font-size: 12px !important;
  line-height: 12px;
  margin: 0 !important;
  margin-left: auto !important;
}

#staffscheduleCt .events-info-cell table {
  border: 0;
}

#staffscheduleCt .events-info-cell table th,
#staffscheduleCt .events-info-cell table td {
  border: 0;
}

#staffscheduleCt .events-info-cell table th {
  padding-right: 1rem;
  text-align: left;
  font-size: 12px;
  font-weight: bolder;
}

#staffscheduleCt .events-info-cell table td {
  font-size: 11px;
}

#staffscheduleCt #tbScheduleCt {
  overflow: scroll;
  box-sizing: border-box;
}

#staffscheduleCt #tbScheduleCt table th {
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  color: #000;
}

#staffscheduleCt #staffscheduleTable {
  box-sizing: border-box;
}

#staffscheduleCt #func {
  margin-bottom: 23px;
}

@media print {
  body.staff-schedule-print {
    background-color: #fff;
  }

  body.staff-schedule-print header {
    display: none;
  }

  body.staff-schedule-print #context-search-bar {
    display: none !important;
  }

  body.staff-schedule-print .hidden-xs-down {
    display: none;
  }

  body.staff-schedule-print section.section {
    display: none;
  }

  body.staff-schedule-print .body-footer {
    display: none;
  }

  body.staff-schedule-print #menu-mobile {
    display: none;
  }

  body.staff-schedule-print #staffscheduleCt {
    position: absolute;
    background-color: transparent;
  }

  body.staff-schedule-print #staffscheduleCt a {
    display: none;
  }

  body.staff-schedule-print #staffscheduleCt #staffscheduleTable {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  body.staff-schedule-print #staffscheduleCt #staffscheduleTable #tbScheduleCt {
    background-color: white;
    height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: visible !important;
  }
}
