@use "sass:color";
@use "../definitions";

#survey-form,
.survey-table-form {
  .survey-error {
    border: 2px solid $brand-danger;
  }
}

.survey {
  section .section {
    margin-bottom: 20px;
    box-shadow: $standard-shadow;
  }

  section {
    h1 {
      background: $background-white;
      color: $typography-secondary-color-dark;
      padding: 0;
      margin: .625rem 0;
    }

    h2 {
      background: $background-white;
      color: $typography-secondary-color-dark;
      margin-left: 0;
      margin-right: 0;
    }

    border: none;
  }

  .info {
    color: $primary;
  }

  .warning {
    color: $warning;
  }

  .error {
    border: $survey-error-border;
  }

  .attachment-button {
    text-align: center;
    margin: 0;
    top: 0;
    right: 0;
    color: #fff;
    border-radius: 0;
    border: 1px solid $autocomplete-border-color;
    font-size: 0.875rem;
    padding: 0;
    height: 42px;
    width: 60px;

    &.button {
      // for anchors trying to look like a button
      line-height: 42px;
    }

    &.negative {
      border: 1px solid color.adjust($button-danger, $lightness: -10%);
    }
  }

  .pagehead-control {
    display: block;
    border-bottom: 1px solid $primary;
    margin: -10px 0 20px;
    padding: 14px 0; // left justify Save/Cancel Form buttons - override survey.less
  }

  .pagemessage {
    transition: $survey-transition;
    display: block;
  }

  .panel {
    position: relative;
    min-height: 20px;
    padding: 19px;
    background: $survey-panel-background;
    transition: all 1.5s cubic-bezier(0, 1, 0.5, 1);
    margin: 0;

    &[aria-expanded="false"] {
      height: 55px;
    }

    i {
      font-size: 20px;
    }

    &.panel-info {
      padding: .625rem;

      @media #{$medium} {
        padding-left: 2.25rem;
      }

      i {
        position: absolute;

        @media #{$small} {
          top: -.625rem;
          right: -.625rem;
        }

        @media #{$medium} {
          top: .625rem;
          left: .625rem;
        }
      }
    }

    .action {
      position: absolute;
      display: block;
      top: -2px;
      right: -5px;
      padding: 5px;
      color: $survey-action-color;
      background-color: $white;
      border: $survey-action-border;
      cursor: pointer;
      border-top: 0;
      border-right: 0;
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);
    }

    .control {
      padding: 10px;
    }
  }

  .stacked {
    margin-left: 10px;
  }

  p {
    color: rgba(0 0 0 / 67%);
  }

  section p {
    font-size: 0.8125rem;
    color: rgba(0 0 0 / 80%);
  }

  .uppercase {
    text-transform: uppercase;
  }

  [data-survey-edit-row-template] {
    display: none;
  }

  [data-survey-control-edit-row] .mandatory-wrapper {
    display: inline-block;
  }

  span.empty-cell {
    color: color.adjust($typography-secondary-color-dark, $lightness: 10%);

    &.required {
      color: $mandatory;
    }
  }

  .table-radio-label.form-control-danger {
    color: $brand-danger;
  }

  .question {
    .question-heading {
      margin: 1.25rem 0 0.3125rem;
      padding: 0;
    }

    @media #{$small} {
      .instruction {
        margin: 0.3125rem 0;
        padding: 0;
      }

      .form-item {
        margin-top: 0;
      }

      .form-item:first-of-type>label {
        display: none;
      }
    }

    @media #{$medium} {
      .question-heading,
      .instruction {
        padding-left: 0;
      }
    }
  }

  @mixin expand-with-border-top {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 0;
    border-top: 1px solid $border-color;
    padding: .625rem;
  }

    @mixin expand-with-border-bottom {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 0;
    border-bottom: 1px solid $border-color;
    padding: .625rem;
  }

  .section-message {
    padding: 0 10px;
    margin: 0 -10px;
    border-bottom: 1px solid $primary;
  }

  .survey-actions-top {
    @include expand-with-border-top;
    @include expand-with-border-bottom;

    padding: 0.3125rem;
    margin-top: 15px;
  }

  .survey-actions {
    @media #{$small} {
      button {
        width: 100%;
      }
    }

    @media #{$medium} {
      display: flex;

      .expand {
        flex: 1 1 1px;
      }
    }
  }

  [role="tabpanel"]>& {
    margin: -10px 0;
  }

  & .section:first-of-type {
    margin-top: 0;
  }

  .paging {
    display: flex;
    padding: 10px 0;
  }

  .survey-actions-bottom {
    @include expand-with-border-top;

    border-color: $border-color;
    padding: 0.3125rem;
  }

  .survey-question-table {
    tr:not(.nth-of-type-hack) {
      height: 30px;
      vertical-align: top;
    }
  }
}

.comments {
  position: relative;
  margin-top: 10px;
  list-style-type: none;
  text-align: left;

  span {
    font-size: 0.8125rem;
  }
}

.comment {
  margin: 0;
  padding: $padding;
  position: relative;
  width: auto;
  background: $background-color;
  margin-bottom: 10px;

  img {
    float: left;
    left: -50px;
    top: 0;
    position: absolute;
  }

  &::after {
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent $background-color;
    display: block;
    width: 0;
    z-index: 1;
    margin-top: -15px;
    left: -15px;
    top: 50%;
  }

  .comment-byline {
    margin: 0 0 2px;
    font-weight: normal;
    font-style: normal;
  }

  .commenter {
    font-weight: bold;
    font-size: 1rem;
    color: $typography-secondary-color-dark;
  }

  .time {
    display: block;
    font-weight: 600;
    font-size: 0.6875rem;
  }

  .comment-body {
    font-size: 0.875rem;
  }
}

[data-table-actions-table] {
  [text-alt-inline] {
    display: none;
  }

  [show-alt-text] {
    [text-default] {
      display: none;
    }

    [text-alt-inline] {
      display: inline;
    }
  }
}

// let users set the case they prefer in tables
.survey label {
  text-transform: none;
}

.survey .radio-table-group td.experimental {
  font-size: 0.875rem;
  font-weight: 400;
  color: #959a9f;
}