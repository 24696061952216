// CSS copy/pasted from ui-1.2.24

.fc {
  direction: ltr;
  text-align: left; }

.fc-rtl {
  text-align: right; }

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1rem; }

/* Colors
-------------------------------------------------------------------------------------------------- */
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content {
  border-color: #bdc0c3; }

.fc-unthemed .fc-popover {
  border-color: #bdc0c3;
  background-color: white; }

.fc-unthemed .fc-divider {
  background: #eeeff0; }

.fc-unthemed .fc-popover .fc-header {
  background: #eeeff0; }

  .fc-unthemed .fc-popover .fc-header .fc-close {
    color: #5c6165; }

.fc-unthemed .fc-today {
  background: rgb(255 153 51 / 10%); }

.fc-highlight {
  /* when user is selecting cells */
  background: #bd2c00;
  opacity: .3; }

.fc-bgevent {
  /* default look for background events */
  background: #6cc644;
  opacity: .3; }

.fc-nonbusiness {
  /* default look for non-business-hours areas */

  /* will inherit .fc-bgevent's styles */
  background: #9ba1a5; }

/* Icons (inline elements with styled text that mock arrow icons)
-------------------------------------------------------------------------------------------------- */
.fc-icon {
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  text-align: center;
  overflow: hidden;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  user-select: none; }

  .fc-icon::after {
    position: relative; }

/*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon-left-single-arrow::after {
  content: "\02039";
  font-size: 200%;
  top: -7%; }

.fc-icon-right-single-arrow::after {
  content: "\0203A";
  font-size: 200%;
  top: -7%; }

.fc-icon-left-double-arrow::after {
  content: "\000AB";
  font-size: 160%;
  top: -7%; }

.fc-icon-right-double-arrow::after {
  content: "\000BB";
  font-size: 160%;
  top: -7%; }

.fc-icon-left-triangle::after {
  content: "\25C4";
  font-size: 125%;
  top: 3%; }

.fc-icon-right-triangle::after {
  content: "\25BA";
  font-size: 125%;
  top: 3%; }

.fc-icon-down-triangle::after {
  content: "\25BC";
  font-size: 125%;
  top: 2%; }

.fc-icon-x::after {
  content: "\000D7";
  font-size: 200%;
  top: 6%; }

/* Buttons (styled <button> tags, normalized to work cross-browser)
-------------------------------------------------------------------------------------------------- */
.fc button {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.125rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.0625rem;
  transition: all 0.2s ease-in-out;
  margin: 0;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 7.5%);
  color: #9ba1a5;
  background-image: none;
  background-color: transparent;
  border-color: #9ba1a5; }

  .fc button:focus, .fc button.focus, .fc button:active:focus, .fc button:active.focus, .fc button.active:focus, .fc button.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

  .fc button:focus, .fc button:hover {
    text-decoration: none; }

  .fc button.focus {
    text-decoration: none; }

  .fc button:active, .fc button.active, .fc button.fc-state-active {
    background-image: none;
    background-color: #9ba1a5;
    color: white;
    outline: 0;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%); }

  .fc button.disabled, .fc button:disabled {
    cursor: not-allowed;
    background-image: none;
    background-color: #9ba1a5 !important;
    color: white !important;
    outline: 0;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%); }

    .fc button.disabled:hover, .fc button:disabled:hover {
      border-color: #9ba1a5 !important; }

  .fc button:hover {
    color: white;
    background-color: #80888d;
    border-color: #7b8388; }

  .fc button:focus, .fc button.focus {
    color: white;
    background-color: #80888d;
    border-color: #7b8388; }

  .fc button:active, .fc button.active,
  .open > .fc button.dropdown-toggle {
    color: white;
    background-color: #80888d;
    border-color: #7b8388;
    background-image: none;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%); }

    .fc button:active:hover, .fc button:active:focus, .fc button:active.focus, .fc button.active:hover, .fc button.active:focus, .fc button.active.focus,
    .open > .fc button.dropdown-toggle:hover,
    .open > .fc button.dropdown-toggle:focus,
    .open > .fc button.dropdown-toggle.focus {
      color: white;
      background-color: #6f767b;
      border-color: #5b6165; }

  .fc button.disabled:focus, .fc button.disabled.focus, .fc button:disabled:focus, .fc button:disabled.focus {
    background-color: #9ba1a5;
    border-color: #9ba1a5; }

  .fc button.disabled:hover, .fc button:disabled:hover {
    background-color: #9ba1a5;
    border-color: #9ba1a5; }

  .fc button:hover {
    color: #fff;
    background-color: #9ba1a5;
    border-color: #9ba1a5; }

  .fc button:focus, .fc button.focus {
    color: #fff;
    background-color: #9ba1a5;
    border-color: #9ba1a5; }

  .fc button:active, .fc button.active,
  .open > .fc button.dropdown-toggle {
    color: #fff;
    background-color: #9ba1a5;
    border-color: #9ba1a5; }

    .fc button:active:hover, .fc button:active:focus, .fc button:active.focus, .fc button.active:hover, .fc button.active:focus, .fc button.active.focus,
    .open > .fc button.dropdown-toggle:hover,
    .open > .fc button.dropdown-toggle:focus,
    .open > .fc button.dropdown-toggle.focus {
      color: #fff;
      background-color: #6f767b;
      border-color: #5b6165; }

  .fc button.disabled:focus, .fc button.disabled.focus, .fc button:disabled:focus, .fc button:disabled.focus {
    border-color: #d1d3d5; }

  .fc button.disabled:hover, .fc button:disabled:hover {
    border-color: #d1d3d5; }

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -.05rem;

  /* seems to be a good adjustment across browsers */
  margin: 0 .25rem;
  vertical-align: middle; }

.fc-state-active {
  background-image: none !important;
  outline: 0 !important;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%); }

/* Buttons Groups
-------------------------------------------------------------------------------------------------- */
.fc-button-group {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

  .fc-button-group > .fc-button {
    position: relative;
    float: left; }

    .fc-button-group > .fc-button:focus, .fc-button-group > .fc-button:active, .fc-button-group > .fc-button.active {
      z-index: 2; }

    .fc-button-group > .fc-button:hover {
      z-index: 2; }

.fc-button-group .fc-button + .fc-button,
.fc-button-group .fc-button + .fc-button-group,
.fc-button-group .fc-button-group + .fc-button,
.fc-button-group .fc-button-group + .fc-button-group {
  margin-left: -1px; }

.fc-button-group > .fc-button:not(:first-child, :last-child, .dropdown-toggle) {
  border-radius: 0; }

.fc-button-group > .fc-button:first-child {
  margin-left: 0; }

  .fc-button-group > .fc-button:first-child:not(:last-child, .dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

.fc-button-group > .fc-button:last-child:not(:first-child),
.fc-button-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.fc-button-group > .fc-button-group {
  float: left; }

.fc-button-group > .fc-button-group:not(:first-child, :last-child) > .fc-button {
  border-radius: 0; }

.fc-button-group > .fc-button-group:first-child:not(:last-child) > .fc-button:last-child,
.fc-button-group > .fc-button-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.fc-button-group > .fc-button-group:last-child:not(:first-child) > .fc-button:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.fc-button-group .dropdown-toggle:active,
.fc-button-group.open .dropdown-toggle {
  outline: 0; }

/* Popover
-------------------------------------------------------------------------------------------------- */
.fc-popover {
  position: absolute;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 15%); }

  .fc-popover .fc-header {
    /* TODO: be more consistent with fc-head/fc-body */
    padding: .125rem .25rem; }

    .fc-popover .fc-header .fc-title {
      margin: 0 .125rem; }

    .fc-popover .fc-header .fc-close {
      cursor: pointer; }

.fc-ltr .fc-popover .fc-header .fc-title {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-title {
  float: right; }

.fc-ltr .fc-popover .fc-header .fc-close {
  float: right; }

/* unthemed */
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid; }

  .fc-unthemed .fc-popover .fc-header .fc-close {
    font-size: .9em;
    margin-top: .125rem; }

/* jqui themed */
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;

  /* where they meet, let the header have the border */ }

/* Misc Reusable Components
-------------------------------------------------------------------------------------------------- */
.fc-divider {
  border-style: solid;
  border-width: 1px; }

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 .125rem;

  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0; }

.fc-clear {
  clear: both; }

.fc-bg, .fc-bgevent-skeleton, .fc-highlight-skeleton, .fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-bg {
  bottom: 0;

  /* strech bg to bottom edge */ }

  .fc-bg table {
    height: 100%;

    /* strech bg to bottom edge */ }

/* Tables
-------------------------------------------------------------------------------------------------- */
.fc table {
  width: 100%;
  box-sizing: border-box;

  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;

  /* normalize cross-browser */ }

.fc th {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

  .fc td.fc-today {
    border-style: double;

    /* overcome neighboring borders */ }

.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */

  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0; }

/* Fake Table Rows
-------------------------------------------------------------------------------------------------- */
.fc-row {
  position: relative; }

  .fc-row table {
    /* don't put left/right border on anything within a fake row.
       the outer tbody will worry about this */
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;

    /* no bottom borders on rows */
    border-bottom: 0 hidden transparent; }

  .fc-row:first-child table {
    border-top: 0 hidden transparent;

    /* no top border on first row */ }

  .fc-row .fc-bg {
    z-index: 1; }

  .fc-row .fc-bgevent-skeleton, .fc-row .fc-highlight-skeleton {
    bottom: 0;

    /* stretch skeleton to bottom of row */ }

  .fc-row .fc-bgevent-skeleton table {
    height: 100%;

    /* stretch skeleton to bottom of row */ }

  .fc-row .fc-highlight-skeleton table {
    height: 100%;

    /* stretch skeleton to bottom of row */ }

  .fc-row .fc-highlight-skeleton td {
    border-color: transparent; }

  .fc-row .fc-bgevent-skeleton {
    z-index: 2; }

    .fc-row .fc-bgevent-skeleton td {
      border-color: transparent; }

  .fc-row .fc-highlight-skeleton {
    z-index: 3; }

  .fc-row .fc-content-skeleton {
    position: relative;
    z-index: 4;
    padding-bottom: .125rem;

    /* matches the space above the events */ }

  .fc-row .fc-helper-skeleton {
    z-index: 5; }

  .fc-row .fc-content-skeleton td, .fc-row .fc-helper-skeleton td {
    /* see-through to the background below */
    background: none;

    /* in case <td>s are globally styled */
    border-color: transparent;

    /* don't put a border between events and/or the day number */
    border-bottom: 0; }

  .fc-row .fc-content-skeleton tbody td, .fc-row .fc-helper-skeleton tbody td {
    /* don't put a border between event cells */
    border-top: 0; }

/* Day Row (used within the header and the DayGrid)
-------------------------------------------------------------------------------------------------- */

/* highlighting cells & background event skeleton */

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/

/* Scrolling Container
-------------------------------------------------------------------------------------------------- */
.fc-scroller {
  -webkit-overflow-scrolling: touch; }

  .fc-scroller > .fc-day-grid, .fc-scroller > .fc-time-grid {
    position: relative;

    /* re-scope all positions */
    width: 100%;

    /* hack to force re-sizing this inner element when scrollbars appear/disappear */ }

/* TODO: move to agenda/basic */

/* Global Event Styles
-------------------------------------------------------------------------------------------------- */
.fc-event {
  position: relative;

  /* for resize handle and other inner positioning */
  display: block;

  /* make the <a> tag block */
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 0.125rem;
  border: 1px solid #387bbd;

  /* default BORDER color */
  background-color: #387bbd;

  /* default BACKGROUND color */
  font-weight: normal;

  /* undo jqui's ui-widget-header bold */
  color: white;

  /* default TEXT color */
  text-decoration: none;

  /* if <a> has an href */ }

  @media (width <= 960px) {
    .fc-event {
      font-size: 0.75rem; } }

  .fc-event:hover {
    color: white;

    /* default TEXT color */
    text-decoration: none;

    /* if <a> has an href */ }

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.ui-widget .fc-event {
  color: white;

  /* default TEXT color */
  text-decoration: none;

  /* if <a> has an href */ }

.fc-event[href], .fc-event.fc-draggable {
  cursor: pointer;

  /* give events with links and draggable events a hand mouse pointer */ }

.fc-not-allowed {
  /* to override an event's custom cursor */
  cursor: not-allowed; }

  .fc-not-allowed .fc-event {
    /* to override an event's custom cursor */
    cursor: not-allowed; }

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: white;
  opacity: .25; }

.fc-event .fc-content {
  position: relative;
  z-index: 2; }

.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
  display: none; }

.fc-event.fc-allow-mouse-resize .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block; }

.fc-event.fc-selected {
  z-index: 9999 !important;

  /* overcomes inline z-index */
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%); }

  .fc-event.fc-selected .fc-resizer {
    /* only show when hovering or selected (with touch) */
    display: block; }

    .fc-event.fc-selected .fc-resizer::before {
      /* 40x40 touch area */
      content: "";
      position: absolute;
      z-index: 9999;

      /* user of this util can scope within a lower z-index */
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2rem;
      margin-left: -1.25rem;
      margin-top: -1.25rem; }

  .fc-event.fc-selected.fc-dragging {
    box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 30%); }

/* resizer (cursor AND touch devices) */

/* resizer (touch devices) */

/* hit area */

/* Event Selection (only for touch devices)
-------------------------------------------------------------------------------------------------- */

/* Horizontal Events
-------------------------------------------------------------------------------------------------- */

/* bigger touch area when selected */
.fc-h-event.fc-selected::before {
  content: "";
  position: absolute;
  z-index: 3;

  /* below resizers */
  inset: -.65rem 0;
}

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start, .fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;

  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;

  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* resizer (cursor AND touch devices) */

/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer, .fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;

  /* overcome border */ }

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer, .fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;

  /* overcome border */ }

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: .5rem;
  top: -1px;

  /* overcome top border */
  bottom: -1px;

  /* overcome bottom border */ }

.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 0.125rem;
  border-width: 1px;
  width: .25rem;
  height: .25rem;
  border-style: solid;
  border-color: inherit;
  background: white;

  /* vertically center */
  top: 50%;
  margin-top: -.25rem; }

/* resizer (touch devices) */

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer, .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -.25rem;

  /* centers the 8x8 dot on the left edge */ }

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer, .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -.25rem;

  /* centers the 8x8 dot on the right edge */ }

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px .125rem 0;

  /* spacing between events and edges */
  padding: 0 1px; }

  .fc-day-grid-event.fc-selected::after {
    content: "";
    position: absolute;
    z-index: 1;

    /* same z-index as fc-bg, behind text */

    /* overcome the borders */
    inset: -1px;

    /* darkening effect */
    background: black;
    opacity: .25; }

  .fc-day-grid-event .fc-content {
    /* force events to be one-line tall */
    white-space: nowrap;
    overflow: hidden; }

  .fc-day-grid-event .fc-time {
    font-weight: bold; }

/* resizer (cursor devices) */

/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer, .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -.125rem;

  /* to the day cell's edge */ }

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer, .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -.125rem;

  /* to the day cell's edge */ }

/* Event Limiting
-------------------------------------------------------------------------------------------------- */

/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px .125rem;
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: none; }

  a.fc-more:hover {
    text-decoration: underline; }

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none; }

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;

  /* make the "more" popover one higher than this */ }

.fc-more-popover {
  z-index: 2;
  width: 14rem; }

  .fc-more-popover .fc-event-container {
    padding: .5rem; }

/* Now Indicator
-------------------------------------------------------------------------------------------------- */
.fc-now-indicator {
  position: absolute;
  border: 0 solid #bd2c00; }

/* Utilities
-------------------------------------------------------------------------------------------------- */
.fc-unselectable {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

/* Toolbar
-------------------------------------------------------------------------------------------------- */
.fc-toolbar {
  text-align: center;
  margin-bottom: 1rem; }

  .fc-toolbar .fc-left {
    float: left; }

  .fc-toolbar .fc-right {
    float: right; }

  .fc-toolbar .fc-center {
    display: inline-block; }

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75rem; }

.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0; }

/* the first thing within each left/center/right section */

/* title text */
.fc-toolbar h2 {
  margin: 0; }

.fc-toolbar button {
  position: relative; }

.fc-toolbar .fc-state-hover, .fc-toolbar .ui-state-hover {
  z-index: 2; }

.fc-toolbar .fc-state-down {
  z-index: 3; }

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active {
  z-index: 4; }

.fc-toolbar button:focus {
  z-index: 5; }

/* button layering (for border precedence) */

/* View Structure
-------------------------------------------------------------------------------------------------- */

/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */

/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container {
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem; }

  .fc-view-container * {
    box-sizing: content-box; }

    .fc-view-container *::before, .fc-view-container *::after {
      box-sizing: content-box; }

.fc-view {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1; }

  .fc-view > table {
    /* so dragged elements can be above the view's main element */
    position: relative;
    z-index: 1; }

/* BasicView
-------------------------------------------------------------------------------------------------- */

/* day row structure */
.fc-basicWeek-view .fc-content-skeleton, .fc-basicDay-view .fc-content-skeleton {
  /* we are sure there are no day numbers in these views, so... */
  padding-top: 1px;

  /* add a pixel to make sure there are .125rem padding above events */
  padding-bottom: 1rem;

  /* ensure a space at bottom of cell for user selecting/clicking */ }

.fc-basic-view .fc-body .fc-row {
  min-height: 4rem;

  /* ensure that all rows are at least this tall */ }

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden; }

  .fc-row.fc-rigid .fc-content-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

/* week and day number styling */
.fc-basic-view .fc-week-number, .fc-basic-view .fc-day-number {
  padding: 0 .125rem; }

.fc-basic-view td.fc-week-number span, .fc-basic-view td.fc-day-number {
  padding-top: .125rem;
  padding-bottom: .125rem; }

.fc-basic-view .fc-week-number {
  text-align: center; }

  .fc-basic-view .fc-week-number span {
    /* work around the way we do column resizing and ensure a minimum width */
    display: inline-block;
    min-width: 1.25rem; }

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: right; }

.fc-rtl .fc-basic-view .fc-day-number {
  text-align: left; }

.fc-day-number.fc-other-month {
  opacity: .3; }

/* AgendaView all-day area
-------------------------------------------------------------------------------------------------- */
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;

  /* so the "more.." popover will be over the time grid */ }

  .fc-agenda-view .fc-day-grid .fc-row {
    min-height: 3em;

    /* all-day section will never get shorter than this */ }

    .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
      padding-top: 1px;

      /* add a pixel to make sure there are .125rem padding above events */
      padding-bottom: 1rem;

      /* give space underneath events for clicking/selecting days */ }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
-------------------------------------------------------------------------------------------------- */
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 .25rem;
  white-space: nowrap; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

.ui-widget td.fc-axis {
  font-weight: normal;

  /* overcome jqui theme making it bold */ }

/* TimeGrid Structure
-------------------------------------------------------------------------------------------------- */
.fc-time-grid-container {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1; }

.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
  min-height: 100%;

  /* so if height setting is 'auto', .fc-bg stretches to fill height */ }

  .fc-time-grid table {
    /* don't put outer borders on slats/bg/content/etc */
    border: 0 hidden transparent; }

  .fc-time-grid > .fc-bg {
    z-index: 1; }

  .fc-time-grid .fc-slats, .fc-time-grid > hr {
    /* the <hr> AgendaView injects when grid is shorter than scroller */
    position: relative;
    z-index: 2; }

  .fc-time-grid .fc-content-col {
    position: relative;

    /* because now-indicator lives directly inside */ }

  .fc-time-grid .fc-content-skeleton {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0; }

  .fc-time-grid .fc-business-container {
    position: relative;
    z-index: 1; }

  .fc-time-grid .fc-bgevent-container {
    position: relative;
    z-index: 2; }

  .fc-time-grid .fc-highlight-container {
    position: relative;
    z-index: 3; }

  .fc-time-grid .fc-event-container {
    position: relative;
    z-index: 4; }

  .fc-time-grid .fc-now-indicator-line {
    z-index: 5; }

  .fc-time-grid .fc-helper-container {
    /* also is fc-event-container */
    position: relative;
    z-index: 6; }

  .fc-time-grid .fc-slats td {
    height: 1.5rem;
    border-bottom: 0;

    /* each cell is responsible for its top border */ }

  .fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dotted; }

  .fc-time-grid .fc-slats .ui-widget-content {
    /* for jqui theme */
    background: none;

    /* see through to fc-bg */ }

  .fc-time-grid .fc-highlight-container {
    /* a div within a cell within the fc-highlight-skeleton */
    position: relative;

    /* scopes the left/right of the fc-highlight to be in the column */ }

  .fc-time-grid .fc-highlight {
    position: absolute;
    left: 0;
    right: 0;

    /* top and bottom will be in by JS */ }

/* divs within a cell within the fc-content-skeleton */

/* TimeGrid Slats (lines that run horizontally)
-------------------------------------------------------------------------------------------------- */

/* TimeGrid Highlighting Slots
-------------------------------------------------------------------------------------------------- */

/* TimeGrid Event Containment
-------------------------------------------------------------------------------------------------- */
.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 .125rem; }

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 .125rem 0 2.5%; }

.fc-time-grid .fc-event {
  position: absolute;
  z-index: 1;

  /* scope inner z-index's */ }

.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;

  /* scope inner z-index's */

  /* background events always span full width */
  left: 0;
  right: 0; }

/* Generic Vertical Event
-------------------------------------------------------------------------------------------------- */
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */

  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;

  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;

  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;

  /* don't let the bg flow over rounded corners */ }

  .fc-time-grid-event.fc-selected {
    /* need to allow touch resizers to extend outside event's bounding box */

    /* common fc-selected styles hide the fc-bg, so don't need this anyway */
    overflow: visible; }

    .fc-time-grid-event.fc-selected .fc-bg {
      display: none;

      /* hide semi-white background, to appear darker */ }

  .fc-time-grid-event .fc-content {
    overflow: hidden;

    /* for when .fc-selected */ }

  .fc-time-grid-event .fc-time, .fc-time-grid-event .fc-title {
    padding: 0 1px; }

  .fc-time-grid-event .fc-time {
    font-size: .85rem;
    white-space: nowrap; }

  .fc-time-grid-event.fc-short .fc-content {
    /* don't wrap to second line (now that contents will be inline) */
    white-space: nowrap; }

  .fc-time-grid-event.fc-short .fc-time, .fc-time-grid-event.fc-short .fc-title {
    /* put the time and title on the same line */
    display: inline-block;
    vertical-align: top; }

  .fc-time-grid-event.fc-short .fc-time span {
    display: none;

    /* don't display the full time text... */ }

  .fc-time-grid-event.fc-short .fc-time::before {
    content: attr(data-start);

    /* ...instead, display only the start time */ }

  .fc-time-grid-event.fc-short .fc-time::after {
    content: "\000A0-\000A0";

    /* seperate with a dash, wrapped in nbsp's */ }

  .fc-time-grid-event.fc-short .fc-title {
    font-size: 0.875rem;

    /* make the title text the same size as the time */
    padding: 0;

    /* undo padding from above */ }

  .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
    left: 0;
    right: 0;
    bottom: 0;
    height: .5rem;
    overflow: hidden;
    line-height: .5rem;
    font-size: 0.875rem;
    font-family: monospace;
    text-align: center;
    cursor: s-resize; }

    .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer::after {
      content: "="; }

  .fc-time-grid-event.fc-selected .fc-resizer {
    /* 10x10 dot */
    border-radius: 0.125rem;
    border-width: 1px;
    width: .5rem;
    height: .5rem;
    border-style: solid;
    border-color: inherit;
    background: white;

    /* horizontally center */
    left: 50%;
    margin-left: -.25rem;

    /* center on the bottom edge */
    bottom: -.25rem; }

/* short mode, where time and title are on the same line */

/* resizer (cursor device) */

/* resizer (touch device) */

/* Now Indicator
-------------------------------------------------------------------------------------------------- */
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0; }

.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -.25rem;

  /* vertically center on top coordinate */ }

/* arrow on axis */
.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;

  /* triangle pointing right... */
  border-width: .25rem 0 .25rem .25rem;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;

  /* triangle pointing left... */
  border-width: .25rem .25rem .25rem 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }

/*!
 * FullCalendar Scheduler v1.3.2
 * Docs & License: http://fullcalendar.io/scheduler/
 * (c) 2016 Adam Shaw
 */

/* TODO: break this file up */

/* Scroller
-------------------------------------------------------------------------------------------------- */
.fc-scroller-clip {
  overflow: hidden;

  /* for clipping scrollbars */
  position: relative;

  /* so things like scrollfollowers can attach to this */ }

/* supresses rendering of native scrollbars */

/* on .fc-scroller */
.fc-no-scrollbars {
  background: rgb(255 255 255 / 0%);

  /* hack for dynamic DOM nodes (in Chrome OSX at least) */ }

  .fc-no-scrollbars::-webkit-scrollbar {
    width: 0;
    height: 0; }

.fc-scroller-canvas {
  position: relative;

  /* origin for bg */
  box-sizing: border-box;

  /* so that padding (for gutter) will be part of height */
  min-height: 100%; }

  .fc-scroller-canvas > .fc-bg {
    z-index: 1;

    /* make default? */ }

  .fc-scroller-canvas > .fc-content {
    z-index: 2;

    /* make default? */
    position: relative;

    /* origin for inner content */
    border-style: solid;
    border-width: 0; }

/* for themed, hard to get the border-color, so just forget it (REVISIT) */
.ui-widget .fc-scroller-canvas > .fc-content {
  border-color: transparent; }

.fc-scroller-canvas.fc-gutter-left > .fc-content {
  border-left-width: 1px;
  margin-left: -1px; }

.fc-scroller-canvas.fc-gutter-right > .fc-content {
  border-right-width: 1px;
  margin-right: -1px; }

.fc-scroller-canvas.fc-gutter-top > .fc-content {
  border-top-width: 1px;
  margin-top: -1px; }

.fc-scroller-canvas.fc-gutter-bottom > .fc-content {
  border-bottom-width: 1px;
  margin-bottom: -1px; }

/* View Structure
-------------------------------------------------------------------------------------------------- */
.fc-rtl .fc-timeline {
  direction: rtl; }

.fc-timeline .fc-divider {
  width: .25rem;
  border-style: double;

  /* overcome neighboring borders */ }

.fc-timeline .fc-head > tr > .fc-divider {
  border-bottom: 0; }

.fc-timeline .fc-body > tr > .fc-divider {
  border-top: 0; }

.fc-timeline .fc-body .fc-divider.ui-widget-header {
  background-image: none; }

.fc-scrolled .fc-head .fc-scroller {
  z-index: 2;

  /* so drop shadow will go above body panes */ }

.fc-timeline.fc-scrolled .fc-head .fc-scroller {
  box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 7.5%); }

.fc-timeline .fc-body .fc-scroller {
  z-index: 1; }

.fc-timeline .fc-scroller-canvas > div > table, .fc-timeline .fc-scroller-canvas > div > div > table {
  border-style: hidden; }

.fc-timeline th, .fc-timeline td {
  text-transform: none;
  white-space: nowrap; }

.fc-timeline .fc-cell-content {
  overflow: hidden; }

.fc-timeline .fc-cell-text {
  font-size: 0.875rem; }

  @media (width <= 960px) {
    .fc-timeline .fc-cell-text {
      font-size: 0.75rem; } }

.fc-timeline .fc-col-resizer {
  cursor: col-resize; }

.fc-timeline th {
  vertical-align: middle; }

.fc-timeline .fc-head .fc-cell-content {
  padding-top: .25rem;
  padding-bottom: .25rem; }

.fc-timeline .fc-body .ui-widget-content {
  background-image: none; }

/*
on all tables that expand to the edges, kill the outer border,
because the container elements take care of it
*/

/* Table Cell Common
-------------------------------------------------------------------------------------------------- */

/*
Cells at the start of a week
TODO: figure out better styling

.fc-ltr .fc-timeline .fc-em-cell div {
  border-left: .25rem solid #eee;
  height: 100%;
}
.fc-rtl .fc-timeline .fc-em-cell {
  border-right-width: .25rem;
}
*/

/* head */

/* body */

/* Resource Area
-------------------------------------------------------------------------------------------------- */
.fc-resource-area {
  width: 30%; }

  .fc-resource-area col {
    width: 40%;
    min-width: 4rem;

    /* will be read by JS */ }

    .fc-resource-area col.fc-main-col {
      width: 60%;

      /* make the first column in a nested setup bigger */ }

.fc-flat .fc-expander-space {
  /* fc-flat is opposite of fc-nested */
  display: none; }

.fc-ltr .fc-resource-area tr > * {
  text-align: left; }

.fc-rtl .fc-resource-area tr > * {
  text-align: right; }

.fc-resource-area .fc-cell-content {
  padding-left: .25rem;
  padding-right: .25rem; }

.fc-resource-area .fc-super th {
  text-align: center; }

.fc-resource-area th > div {
  position: relative; }

.fc-resource-area th .fc-cell-content {
  position: relative;
  z-index: 1; }

.fc-resource-area th .fc-col-resizer {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: .25rem; }

/* head */
.fc-ltr .fc-resource-area th .fc-col-resizer {
  right: -.25rem; }

.fc-rtl .fc-resource-area th .fc-col-resizer {
  left: -.25rem; }

/* body */
tr.fc-collapsed > td {
  /* during the transition */
  overflow: hidden;

  /* prevents absolutely-positioned events from bleeding out */ }

tr.fc-transitioning > td {
  /* during the transition */
  overflow: hidden;

  /* prevents absolutely-positioned events from bleeding out */ }

  tr.fc-transitioning > td > div {
    transition: margin-top 200ms; }

tr.fc-collapsed > td > div {
  margin-top: -.5rem; }

.fc-body .fc-resource-area .fc-cell-content {
  /* might BE the cell */
  padding-top: .5rem;
  padding-bottom: .5rem; }

.fc-no-overlap .fc-body .fc-resource-area .fc-cell-content {
  /* might BE the cell */
  padding-top: .25rem;
  padding-bottom: .25rem; }

.fc-resource-area .fc-icon {
  /* the expander and spacers before the expander */
  width: 1rem;

  /* ensure constant width, esp for empty icons */
  font-size: 1rem;
  vertical-align: middle;
  margin-top: -1%; }

.fc-resource-area .fc-expander {
  cursor: pointer;
  color: #5c6165;

  /* for the icon within */ }

/* Time Area
-------------------------------------------------------------------------------------------------- */
.fc-time-area col {
  min-width: 2.5rem;

  /* detected by JS */ }

/* head */
.fc-ltr .fc-time-area .fc-chrono th {
  text-align: left; }

.fc-rtl .fc-time-area .fc-chrono th {
  text-align: right; }

/* body slats (vertical lines) */
.fc-time-area .fc-slats {
  /* fc-bg is responsible for a lot of this now! */
  position: absolute;
  z-index: 1;
  inset: 0;
}

  .fc-time-area .fc-slats table {
    height: 100%; }

  .fc-time-area .fc-slats .fc-minor {
    border-style: dotted; }

  .fc-time-area .fc-slats td {
    border-width: 0 1px;

    /* need to do this. sometimes -1 margin wouldn't hide the dotted */ }

.fc-ltr .fc-time-area .fc-slats td {
  border-right-width: 0; }

.fc-rtl .fc-time-area .fc-slats td {
  border-left-width: 0; }

/* body content containers
   can be within rows or directly within the pane's content
*/
.fc-time-area .fc-bgevent-container,
.fc-time-area .fc-highlight-container {
  position: absolute;
  z-index: 2;

  /* only for directly within pane. not for row. overridden later */
  top: 0;
  bottom: 0;
  width: 0; }

.fc-ltr .fc-time-area .fc-helper-container,
.fc-ltr .fc-time-area .fc-bgevent-container,
.fc-ltr .fc-time-area .fc-highlight-container {
  left: 0; }

.fc-rtl .fc-time-area .fc-helper-container,
.fc-rtl .fc-time-area .fc-bgevent-container,
.fc-rtl .fc-time-area .fc-highlight-container {
  right: 0; }

.fc-time-area .fc-bgevent,
.fc-time-area .fc-highlight {
  position: absolute;
  top: 0;
  bottom: 0; }

.fc-time-area .fc-rows {
  position: relative;
  z-index: 3; }

  .fc-time-area .fc-rows .ui-widget-content {
    background: none; }

  .fc-time-area .fc-rows td > div {
    position: relative; }

  .fc-time-area .fc-rows .fc-bgevent-container,
  .fc-time-area .fc-rows .fc-highlight-container {
    z-index: 1; }

.fc-time-area .fc-event-container {
  position: relative;
  z-index: 2;

  /* above bgevent and highlight */
  width: 0;

  /* for event positioning. will end up on correct side based on dir */ }

.fc-time-area .fc-helper-container {
  /* also an fc-event-container */
  position: absolute;
  z-index: 3;
  top: 0; }

.fc-time-area .fc-event-container {
  padding-bottom: .5rem;
  top: -1px; }

.fc-time-area tr:first-child .fc-event-container {
  top: 0; }

/* body resource rows */
.fc-no-overlap .fc-time-area .fc-event-container {
  padding-bottom: 0;
  top: 0; }

/* Now Indicator
-------------------------------------------------------------------------------------------------- */
.fc-timeline .fc-now-indicator {
  /* both the arrow and the line */
  z-index: 3;

  /* one above scroller's fc-content */
  top: 0; }

.fc-time-area .fc-now-indicator-arrow {
  margin: 0 -.5rem;

  /* 5, then one more to counteract scroller's negative margins */

  /* triangle pointing down... */
  border-width: .5rem .25rem 0;
  border-left-color: transparent;
  border-right-color: transparent; }

.fc-time-area .fc-now-indicator-line {
  margin: 0 -1px;

  /* counteract scroller's negative margins */
  bottom: 0;
  border-left-width: 1px; }

/* Time Grid Events
-------------------------------------------------------------------------------------------------- */
.fc-timeline-event {
  position: absolute;
  border-radius: 0;
  padding: .125rem 0;
  margin-bottom: 1px; }

.fc-no-overlap .fc-timeline-event {
  padding: .25rem 0;
  margin-bottom: 0; }

/* don't overlap grid lines at the event's end */
.fc-ltr .fc-timeline-event {
  margin-right: 1px; }

.fc-rtl .fc-timeline-event {
  margin-left: 1px; }

.fc-timeline-event .fc-content {
  padding: 0 .125rem;
  white-space: nowrap;
  overflow: hidden; }

.fc-timeline-event .fc-time {
  font-weight: bold;
  padding: 0 1px; }

.fc-rtl .fc-timeline-event .fc-time {
  display: inline-block;

  /* will force it on the other side */ }

.fc-timeline-event .fc-title .fc-title-label {
  display: block;
  font-size: 80%;
  opacity: .8; }

.fc-timeline-event.fc-selected .fc-bg {
  display: none;

  /* hide semi-white background, to appear darker */ }

/* follower logic */
.fc-ltr .fc-timeline-event .fc-title {
  margin: 0;
  padding: 0; }

.fc-rtl .fc-timeline-event .fc-title {
  margin: 0;
  padding: 0; }

.fc-ltr .fc-timeline-event.fc-not-start .fc-title {
  margin: 0; }

.fc-rtl .fc-timeline-event.fc-not-start .fc-title {
  margin: 0; }

.fc-timeline-event.fc-not-start .fc-title,
.fc-body .fc-time-area .fc-following {
  position: relative; }

.fc-timeline-event.fc-not-start .fc-title::before,
.fc-body .fc-time-area .fc-following::before {
  /* generic arrow */
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -.25rem;
  border: .25rem solid black;
  border-top-color: transparent;
  border-bottom-color: transparent;
  opacity: .5; }

.fc-ltr .fc-timeline-event.fc-not-start .fc-title::before,
.fc-ltr .fc-body .fc-time-area .fc-following::before {
  /* LTR. left pointing arrow */
  border-left: 0;
  left: .125rem; }

.fc-rtl .fc-timeline-event.fc-not-start .fc-title::before,
.fc-rtl .fc-body .fc-time-area .fc-following::before {
  /* RTL. right pointing arrow */
  border-right: 0;
  right: .125rem; }

.fc-view-container {
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
}

.fc-view-container * {
  box-sizing: content-box;
}

.fc-state-default.fc-corner-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fc-state-default.fc-corner-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc button {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.125rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.0625rem;
  transition: all 0.2s ease-in-out;
  margin: 0;
  border-color: rgb(155 161 165);
  box-shadow: inset 0 1px 0 rgba(255 255 255 / 15%), 0 1px 1px rgba(0 0 0 / 7.5%);
  color: rgb(155 161 165);
  background-image: none;
  background-color: transparent;
  box-sizing: border-box;
  height: 2.1em;
}

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.fc-timeline .fc-cell-text {
  padding-left: 4px;
  padding-right: 4px;
}

.fc-timeline th,
.fc-timeline td {
  text-transform: none;
  white-space: nowrap;
}

.fc-view,
.fc-view>table {
  position: relative;
  z-index: 1;
}
