.action-items,
.section-accordian {
  .section-summary-text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 82px);

    @media #{$medium} {
      width: calc(100% - 120px);
      line-height: 48px;
      font-size: 24px;
    }

    @media #{$large} {
      width: calc(100% - 180px);
      margin-left: 20px;
      line-height: 64px;
      font-size: 36px;
    }

    .count {
      font-size: 24px;
      display: inline-block;
      text-align: center;
      min-width: 60px;
      vertical-align: middle;
      margin-right: 10px;

      @media #{$medium} {
        min-width: 120px;
        font-size: 48px;
        margin-right: 10px;
      }

      @media #{$large} {
        min-width: 140px;
        font-size: 64px;
        margin-right: 20px;
      }
    }

    .count-label {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 100px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media #{$medium} {
        width: calc(100% - 240px);
      }

      &.no-digits {
        margin-left: 20px;
      }
    }
  }

  [role="tab"] {
    transition: opacity $transition-slowest, transform $transition-slow;
    outline: none;
    border-bottom: 1px solid $border-color;
    padding: $padding;
  }

  .panel {
    transition: opacity $transition-slowest, transform $transition-slow;
    outline: none;
    border-bottom: 1px solid $border-color;
  }

  .panel-header {
    display: flex;
    height: auto;
  }

  .panel-header>.glyphicons {
    font-size: 4em !important;
    color: rgb(0 0 0 / 60%);

    @media #{$medium} {
      font-size: 4em !important;
      padding-top: 22px;
    }
  }

  .item-count {
    font-size: 2rem;
    border-left: 2px solid $secondary;
    margin-left: 40px;
    line-height: 1;
    color: rgb(0 0 0 / 60%);
    padding-left: 20px;

    @media #{$medium} {
      padding-top: 20px;
      font-size: 5rem;
    }
  }

  .item-label {
    font-size: 1.1875rem;
    padding-top: 4px;
    margin-left: 20px;
    color: rgb(0 0 0 / 60%);

    @media #{$medium} {
      padding-top: 30px;
      font-size: 2.5rem;
    }
  }

  .action-item-count {
    position: relative;
    display: block;
    top: 20px;
    width: 50px;
    padding: 4px;
    float: right;
    text-align: center;
    border: 1px solid $primary;
    border-radius: 50%;
    background: $primary;
    color: $white;
  }

  .action-item-icon {
    float: left;
    width: 32px;
    height: 32px;
    padding: 5px 5px 5px 0;
    color: rgb(0 0 0 / 60%);
  }

  .actionable-list {
    padding: 0;
    list-style-type: none;
    overflow: hidden;

    li {
      position: relative;
      z-index: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;

      @media #{$medium} {
        display: flex;
      }

      &.static {
        cursor: auto;
      }

      &[aria-expanded="true"] {
        height: 130px;
      }

      &.selected {
        background: rgb(0 0 0 / 5%);
      }
    }

    [role="group"] {
      z-index: 0;
      background: transparent;
      padding: 10px;
      transition: transform 0.3s cubic-bezier(0.1, 0.7, 0.1, 1);
      transform-style: preserve-3d;
      backface-visibility: hidden;
      user-select: element;

      &:hover {
        background: $border-color;
      }

      @media #{$medium} {
        flex: 1 0 auto;
      }

      &.actions {
        padding: 0.5em 0; // $ 16px

        @media #{$small} {
          display: flex;
          flex-direction: column;
        }
      }

      .date {
        right: 0;
        margin-right: 70px;
        top: 26px;

        i {
          padding-left: 20px;
        }
      }
    }

    &.no-action,
    [data-url=""] {
      cursor: inherit;

      [role="group"] {
        background: transparent;
      }
    }

    .title {
      font-weight: bold;
      font-size: 1.1rem;
      color: $typography-secondary-color-dark;
    }

    .description {
      margin-right: 60px;
    }

    .expand {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 60px;
      padding-top: 20px;
      text-align: center;
      color: rgb(0 0 0 / 60%);
      font-size: 2rem;

      &:hover {
        background: $border-color;
      }

      z-index: 1;
    }

    .expand[aria-expanded="true"] {
      @media #{$medium} {
        background: $sheet-background;
      }
    }

    .info {
      position: relative;
      font-size: 0.6875rem;
    }

    section[role="dialog"] {
      margin-left: 32px;
      border-left: 4px solid $primary;
    }

    .negative {
      transition: $button-transition;
    }
  }

  .cells {
    margin: 0;
    padding: 0;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    overflow: hidden;

    .glyphicons {
      // color: $secondary-shade;
    }

    a.disabled {
      color: grey;
    }

    .bulkupdate-select-all-container {
      display: flex;

      .checkbox-ct {
        flex: 0 0 40px;
        padding: 0.25em;
        text-align: center;
      }

      .checkbox-label {
        padding: 0.25em;
      }
    }

    .list-cell {
      [data-bulkupdate-selector] {
        flex: 0 0 40px;
        text-align: center;
        padding-top: 0.25em;
        display: flex;
        justify-content: center; // center checkbox horizontally
        align-items: center; // center vertically
      }
    }
  }
}

section.standard-list {
  margin-top: 0;
}

.actionable-list2 {
  .actions {
    padding-bottom: 0;
  }

  /** Positioning */
  .list-cell {
    list-style: none;

    [role="group"] {
      display: flex;
      align-items: stretch;

      .expander {
        z-index: 10;
        cursor: pointer;

        .glyphicons-more {
          top: 0;
          font-size: 32px;
        }
      }

      position: relative;

      .sheet {
        @media #{$medium} {
          z-index: 5;
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          border-left: 4px solid $primary;
        }
      }
    }
  }

  /** Adjustments and Padding */
  li [role="group"].actions {
    a {
      margin: 0 0.5em 0.5em 0;

      @media #{$small} {
        margin-right: 0;
      }
    }

    button,
    .button {
      font-size: 1rem;
    }

    @media #{$small} {
      display: flex;
      flex-direction: column;
    }
  }

  .list-cell {
    [role="group"] {
      border-bottom: 1px solid $border-color;

      .expander .glyphicons {
        width: 60px;
        text-align: center;
      }

      .expander {
        margin-left: 0.5em;
      }

      .sheet {
        @media #{$medium} {
          right: 60px;
        }
      }

      .cell-container {
        overflow: hidden; // truncate text that runs behind the expander
      }
    }

    &:last-child [role="group"] {
      border-bottom-width: 0;
    }
  }

  /** Colours */
  .list-cell {
    &.selected {
      background: rgb(0 0 0 / 5%);

      &.no-action:hover {
        background: rgb(0 0 0 / 5%);
      }
    }

    &:hover {
      cursor: pointer;
      background: $border-color;

      &.no-action {
        cursor: inherit;
        background: none;
      }
    }

    [role="group"] {
      .expander {
        @media #{$medium} {
          &[aria-expanded="true"] {
            background: $background-white;
          }
        }
      }
    }
  }
}

/** Cell Template specific Styles */

  /**
        Title
        Subtitle Text
        Label Text       Data Text
        Label Text       Data Text
        Label Text       Data Text
        --
        Title                    Label Text - Data Text
        Subtitle Text            Label Text - Data Text
                                 Label Text - Data Text
     */

@mixin cells-card {
  // Bot all cells might want padding but if you do
  // then say it's a "card" (as in business card).
  padding: 0.5em;
}

@mixin title {
  font-weight: bold;
  font-size: 1.1875rem;
  color: $gray-darkest;
}

@mixin subtitle {
  font-size: 1rem;
}

@mixin normal-text {
  font-size: 0.875rem;

  @media #{$small} {
    font-size: .75rem;
  }
}

@mixin subtext {
  font-size: 0.8125rem;
}

@mixin small {
  font-size: 0.6875rem;
}

@mixin cells-label {
  font-weight: bold;
}

@mixin faint-text {
  color: rgb(0 0 0 / 60%);
}

// Define reusable Mixin
@mixin data-with-label($labelWidth) {
    display: flex;

    .cells-label {
      flex: 0 0 $labelWidth;
      max-width: $labelWidth;
      text-overflow: ellipsis;
      padding-right: .3125rem;
      white-space: nowrap;
      overflow: hidden;
    }

    // MSIE HACK
    .cells-label,
    .data {
      display: inline-block;
    }

    .data {
      flex: 1 1;
    }
}

@mixin data-with-label-default {
  @include data-with-label(10.625rem);

  @media #{$medium} {
    @include data-with-label(9.375rem);
  }

  @media #{$large} {
    @include data-with-label(11.25rem);
  }
}

[data-cell-template="recruit/review-applicants-cell"] {
  @media #{$medium} {
    .primary-group {
      flex: 1 0 12.5rem;
    }

    .secondary-group {
      flex: 1 1 13.75rem;
    }

    .primary-group .f-receiver_assessment {
      @include subtitle;

      .cells-label {
        display: none;
      }
    }

    .secondary-group {
      @include subtext;
      @include faint-text;
    }
  }

  @media #{$small} {
    .f-receiver_assessment,
    .f-other_assessments,
    .f-overall_assessment,
    .f-rank {
      @include data-with-label-default;
    }

    .primary-group .f-receiver_assessment,
    .secondary-group {
      .separator {
        display: none;
      }
    }
  }

  .primary-group > div > *,
  .secondary-group > * {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

[data-cell-template="recruit/review-applicants-documents-cell"] {
  @media #{$medium} {
    .f-documents-wrap > .cells-label {
      flex: 1 0 12.5rem;

      @include subtext;
    }

    .f-documents-wrap > .fields {
      flex: 1 1 17.8125; // documents cell doesn't have the ellipsis menu
    }

    .f-doc_apform,
    .f-doc_resume,
    .f-doc_letter {
      @include subtext;
      @include faint-text;
    }
  }

  @media #{$small} {
    .f-documents-wrap > .cells-label {
      display: none;
    }

    .f-doc_apform,
    .f-doc_resume,
    .f-doc_letter {
      @include data-with-label-default;
    }

    .separator {
      display: none;
    }
  }

  .field > * {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

[data-cell-template="recruit/applicant-details-referees"],
[data-cell-template="recruit/applicant-details-qualifications"],
[data-cell-template="recruit/applicant-details-emphistory"],
[data-cell-template="recruit/applicant-details-licences"] {
  .primary-group {
    flex: 1 0 60%;
  }

  .secondary-group {
    flex: 1 0 40%;
  }

  .primary-group > *,
  .secondary-group > * {
    margin-bottom: 0.5em;
  }

  @media #{$medium} {
    .primary-group {
      padding-right: 1em;
    }

    .f-email .data,
    .f-document .data {
      word-break: break-all;
    }
  }
}

[data-cell-template="recruit/schedule-interviews-interviewers"],
[data-cell-template="recruit/schedule-interviews-interviews"] {
  @media #{$medium} {
    .primary-group {
      flex: 1 0 12.5rem;
    }

    .group-2 {
      flex: 1 1 13.75rem;
    }

    .no-actions {
      flex: 1 1 17.8125rem;
    }
  }
}

[data-cell-template="recruit/schedule-interviews-interviewers"] {
  @media #{$medium} {
    .group-2 {
      @include subtext;
      @include faint-text;
    }

    .f-type .cells-label {
      display: none;
    }
  }

  @media #{$small} {
    .f-type,
    .f-email,
    .f-phone,
    .f-mobile {
      @include data-with-label(8.125rem);
      ;
    }

    .group-2 {
      .separator {
        display: none;
      }
    }
  }
}

[data-cell-template="recruit/schedule-interviews-interviews"] {
  @media #{$medium} {
    .f-date .cells-label,
    .f-time .cells-label,
    .f-time_to .cells-label,
    .f-tzone .cells-label,
    .f-comments .cells-label {
      display: none;
    }

    .time_from_to .separator {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }

    .group-2 {
      @include faint-text;
    }
  }

  @media only screen and (width >= 820px) {
    .group-2 {
      display: flex;

      .group-2-1 {
        flex: 0 0 13.75rem;
      }

      .group-2-2 {
        flex: 1 1 1px;
      }
    }
  }

  @media #{$small} {
    .time_from_to .separator {
      display: none;
    }

    .f-date,
    .f-time,
    .f-time_to,
    .f-tzone,
    .f-comments {
      @include data-with-label(8.125rem);
      ;
    }
  }
}