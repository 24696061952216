@use "sass:color";

#application > header {
  top: 0;
  z-index: $zindex-app-header;
  width: $full-width;
  height: 60px;
  margin: 0;
  overflow: hidden;
  background-color: $primary;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
}

@media only screen {
  #application > header {
    position: fixed;
  }

  #container {
    padding-top: 60px; // Push main content down as header nav is fixed
  }
}

#logo {
  width: 100%;
  height: 32px;
  max-width: 180px;
  background-image: url("../img/logo-aurion-white.png");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* Main navigation */

#menu {
  height: 100%;
  padding: 0 1.25rem;
  pointer-events: all;

  button {
    all: unset;
  }

  a,
  button {
    color: fade($typography-color, 75%);

    &:hover {
      background-color: color.mix($primary, white, 75%);
      color: $typography-color;
    }

    &:focus,
    &[aria-expanded="true"] {
      background-color: color.mix($primary, black, 75%);
      color: $typography-color;
    }
  }

  // apply pre-layout settings
  .nav-menu-item[aria-owns="menu-settings"] {
    .user-photo {
      margin-top: -0.5rem;
      margin-bottom: auto;

      img {
        display: block; // change from inline to remove the unwanted padding blow the image
        width: 40px;
        height: 40px;
      }
    }

    span.user-photo {
      display: inline-block; // MSIE10: remove unwanted space below image
    }
  }

  // Now apply layout settings so that things sit in the correct place.

  display: flex;
  justify-content: space-between; // justify logo left, and nav items right

  .nav-menu-items {
    margin-right: -20px; // line text nav items up with page content (on the RHS) - compensate for margin and padding on last child
    cursor: pointer;
  }

  #logo {
    flex-shrink: 0;
    align-self: center;
  }

  .nav-menu-items {
    [aria-owns="menu-settings"] {
      // layout the settings menu menu item ... so that the photo box is to the left, and the first name is above the last name
      display: flex;

      .name-wrap {
        display: flex;
        flex-direction: column;
        max-width: 120px;
        margin: auto;
        margin-top: -0.125rem;
        margin-bottom: -.125rem;
        line-height: 1.25;

        .name {
          display: inline-block;
        }
      }
    }

    &#pastEmployeesMenu {
      .nav-menu-item {
        &:hover {
          background-color: $brand-danger;
        }
      }
    }

    display: flex; // layout menu items out horizontally
    align-items: flex-end; // bottom align the nav items

    .nav-menu-item.mobile {
      align-self: center; // but vertially center mobile glyphs (not bottom align)
    }

    [aria-owns="menu-settings"] {
      align-items: inherit; // align username text with rest of menu items
    }

    .nav-menu-item {
      display: inline-block;
      padding: 18px 16px;
    }

    .nav-menu-item.settings {
      display: inherit;
    }

    margin-right: -10px; // align the right most items up with page content border - compensate for 10px right padding above

    .nav-menu-item.desktop {
      // hide desktop only menu items in mobile mode
      @media #{$small} {
        display: none;
      }
    }

    .nav-menu-item.mobile {
      // hide mobile only menu items in desktop mode
      @media #{$medium} {
        display: none;
      }
    }

    .nav-menu-item {
      // stop menu items from bunching up when the browser window is too thin
      flex-shrink: 0;

      .name-wrap {
        flex-shrink: 0;
      }
    }
  }
}

// ---------------------------------------------------------------------------
// Global
// ---------------------------------------------------------------------------

footer#menu-mobile {
  h2 {
    // <footer><nav><h2>
    font-size: 1rem;
    text-transform: uppercase;
    color: $typography-primary-color-light;
  }

  [role="menu"] {
    // <footer><nav><ul>
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      white-space: nowrap;
    }
  }

  // ---------------------------------------------------------------------------
  // Normal Mode (Javascript Enabled)
  // ---------------------------------------------------------------------------

  html & {
    h2 {
      // <footer><nav><h2>
      padding: 8px;
      margin: 0;
      font-size: 1rem;
      text-transform: uppercase;
      color: $typography-primary-color-light;

      @media #{$medium} {
        display: none;
      }
    }

    [role="menu"] {
      // <footer><nav><ul>

      display: block;
      padding-bottom: 0.5rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 17.5%);

      .dropdown-divider {
        border-top-color: fade($typography-color, 25%);
      }

      @media #{$medium} {
        display: none;

        &[aria-expanded="true"] {
          display: block;
          position: absolute;
          z-index: $zindex-menu-expanded;
          min-width: 128px;
          outline: 0;
          background-color: color.mix($primary, black, 75%);
        }
      }
    }

    [role="menu"] a {
      display: block;
      padding: 8px 24px 8px 16px;
      text-decoration: none;
      outline: 0;
      color: fade($typography-color, 75%);

      &:hover,
      &:focus {
        background-color: color.mix($primary, white, 75%);
        color: $typography-color;
      }

      &.logout {
        &:hover,
        &:focus {
          background-color: $brand-danger;
          color: white;
        }
      }
    }

    & {
      // #menu-mobile
      position: fixed;
      inset: 60px 0 0;
      overflow: auto;
      pointer-events: all;
      transition: transform $transition-default cubic-bezier(0.1, 0.5, 0.1, 1);
      transform: translateX(-100%);
      color: $typography-primary-color-light !important;
      background-color: $main;
      z-index: $zindex-menu-expanded;

      @media #{$medium} {
        top: 0;
        bottom: auto;
        overflow: visible;
        transform: translateX(0);
      }

      &[aria-expanded="true"] {
        transform: translateX(0);

        &[role="menu"],
        h2 {
          @media #{$small} {
            display: block;
          }
        }
      }

      .submenu-container {
        height: 100%;
        overflow-x: hidden;
      }

      h2 {
        opacity: 1;
        margin-bottom: 5px;
        background: rgb(0 0 0 / 40%);
        border-bottom: 0 none;
      }

      a > h2 {
        background: transparent;
        border: 1px solid rgb(0 0 0 / 40%);
        border-width: 1px 0;
      }
    }
  }
}

body.mobile-menu-visible {
  @media #{$small} {
    overflow: hidden;
  }
}

.nav-jump-link-bar {
  height: auto;
  top: 70px;
}