.tabpanel {
  position: relative;
  margin: 1.25rem 0;
  padding: 1.25rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 7.5%) !important;
}

.tabpanel .help .glyphicons {
  font-size: 20px;
  padding: 2px;
  transition: all 1000ms cubic-bezier(0, 1, 0.5, 1);
  border-radius: 50%;
}

.tabpanel .help .glyphicons:hover {
  cursor: pointer;
  background: rgb(0 0 0 / 20%);
}

.tabpanel h2 {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tabpanel [role="tablist"]:not(.nav) {
  display: flex;
  margin: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

@media #{$medium} {
  .tabpanel [role="tablist"]:not(.nav) {
    min-width: 0;
    overflow-x: inherit;
  }
}

.tabpanel [role="tab"]:not(.nav-link) {
  display: block;
  margin: 10px;
  text-align: center;
  color: $brand-primary;
  text-decoration: none;
  font-size: 24px;
  border-bottom: 1px solid transparent;
}

.tabpanel [role="tab"]:not(.nav-link)[aria-selected="true"] {
  border-left: none;
  border-bottom-color: #5c6165;
  color: #5c6165;
}

.tabpanel [role="tab"]:not(.nav-link):focus,
.tabpanel [role="tab"]:not(.nav-link):hover {
  color: #5c6165;
  border-bottom-color: #5c6165;
}

.tabpanel [role="tab"]:not(.nav-link):last-child {
  border-radius: 0;
}

.tabpanel [role="tab"]:not(.nav-link) + a {
  border-left: none;
}

.tabpanel section {
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
}

.tabpanel section.section {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 6px;
}

.tabpanel section.section:first-of-type {
  border-radius: 0;
}

@media only screen and (width <= 767px) {
  .tabpanel section.section:first-of-type {
    box-shadow: none;
    border-radius: 0;
  }
}

.tabpanel section.section:last-of-type {
  border-radius: 0;
}

@media only screen and (width <= 767px) {
  .tabpanel section.section:last-of-type {
    box-shadow: none;
    border-radius: 0;
  }
}
