#load {
  &.ess-progress {
    top: 0;
    position: fixed;
    height: $progress-height;
    width: $progress-width;
    background-color: $progress-background;
    z-index: $zindex-progress;

    .ess-progress-bar {
      position: absolute;
      height: 100%;
      animation-duration: 3s;
      animation-name: ess-progress-width;
      background-image: linear-gradient(to right, $progress-color, $progress-color);
      background-size: $progress-width $progress-height;
    }
  }
}

@keyframes ess-progress-width {
  0% {
    width: 0;
    transition-timing-function: cubic-bezier(1, 0, .65, .85);
  }

  100% {
    width: 100%;
    transition-timing-function: cubic-bezier(1, 0, .65, .85);
  }
}