/* Absolute Center Spinner */
.dots-loading {
  .dots-box {
    position: fixed;
    z-index: 1100;
    inset: 0;
    overflow: show;
    width: 15rem;
    height: 15rem;
    margin: auto;
    border-radius: 1rem;
    background-color: rgba($brand-primary-darker,.8);

    p {
      padding: 0 2rem;
      text-align: center;
      color: white;
    }
  }
}

.dots-loading-overlay {
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 60%);
  }
}

.dots-spinner,
.dots-spinner::before,
.dots-spinner::after {
  width: 2.5em;
  height: 2.5em;
          animation: loading 1500ms infinite ease-in-out;
  border-radius: 50%;
          animation-fill-mode: both;
}

.dots-spinner {
  font-size: .75rem;
  position: relative;
  margin: 4rem auto;
          animation-delay: -150ms;
  text-indent: -9999em;
  color: white;

  &::before {
    left: -3.5em;
            animation-delay: -300ms;
  }

  &::after {
    left: 3.5em;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: "";
  }
}

.btn-loading-spinner {
  .dots-spinner {
    display: inline-block;
    top: -1.35rem;
    width: .75em;
    height: .75em;
    margin: 0 .325rem;
    font-size: .5rem;
    color: inherit;

    &::before {
      left: -1em;
    }

    &::after {
      left: 1em;
    }

    &::before,
    &::after {
      position: absolute;
      width: .75em;
      height: .75em;
    }
  }

  &:not([data-loading-text=""]) {
    .dots-spinner {
      margin-right: 1.025rem;
      margin-left: -.15rem;
    }

    i {
      &.iconic {
        margin-left: -.25rem;
        margin-right: .5rem;
      }
    }
  }
}

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.25em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.25em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes form-control-spinner {
  to {
    transform: rotate(360deg);
  }
}

.form-control-spinner {
  z-index: 1000;

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -32px;
    border-radius: 50%;
    border: 2px solid rgb(0 0 0 / 20%);
    border-top-color: rgb(0 0 0 / 80%);
    animation: form-control-spinner .6s linear infinite;
  }
}
