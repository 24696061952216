@use "../global/mixins";
@import "../global/animations";

#form-jump-link-bar {
  background: $background-white;
  margin: 20px 0 -20px;
  padding: 5px 5px 0;
}

.scrolly-page-selector {
  // the <UL> element
  margin: 0;
  padding: 0; // Remove the initial left indentation from the <list>
  position: relative; // required for util.scrollIntoView() math to work.
  display: flex;

  /* align the <li> horizontally */
  overflow-x: scroll;

  /* horizontal scroll bar */

  .scrolly-page-selector-item {
    // the <LI> element
    flex-grow: 1;
    flex-basis: 1px; // make all boxes same width
    list-style: none; // remove the disc
    margin-right: 4px;
    min-height: 80px;
    max-height: 80px;
    min-width: 8.125rem;

    /* make the <li><a> elements take up full height */
    display: flex;
    align-items: stretch;

    &:last-of-type {
      margin-right: 0;
    }

    border-top: 4px solid $gray-darkest;
    background: $gray-lightest;

    &[aria-pressed="true"] {
      background: $gray;

      .page-selector-link {
        color: $white;

        &:hover {
          color: $gray-lightest;
        }
      }
    }

    overflow: hidden; // crop cells with too much text
  }

  .page-selector-link {
    flex: 1 1 1px;
    color: $primary-darker;

    /* make the <li><a> elements centered hoizontally */
    padding: 0.625rem;
    display: flex;
    align-items: center;
    text-align: center;

    &:hover {
      color: $primary-dark;
    }
  }

  .page-selector-content {
    flex: 1 1 1px;
  }
}

.progress-tracker {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  margin: 40px auto;
  padding: 0;
  list-style: none;
}

.progress-step {
  display: block;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: 2rem;
}

.progress-step:last-child {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.progress-step:not(:last-child)::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -10;
  top: 0.875rem;
  bottom: 0.875rem;
  right: -1rem;
  width: 100%;
  height: 0.25rem;
  transition: background-color 0;
}

.progress-step.is-active .progress-title {
  font-weight: 400;
}

.progress-step > a {
  display: block;
}

.progress-marker {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 2rem;
  height: 2rem;
  padding-bottom: 2px;
  color: #fff;
  font-weight: 400;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: background-color, border-color;
  transition-duration: 0;
}

.progress-text {
  display: block;
  padding: 1rem 0.6667rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(.hidden-xs-down) {
    padding: 1rem 0.4rem;

    .progress-description {
      white-space: normal;
    }
  }
}

.progress-title {
  margin-top: 0;
}

.progress-step .progress-marker {
  color: #fff;
  background-color: #cacdcf;
}

.progress-step::after {
  background-color: #cacdcf;
}

.progress-step .progress-text,
.progress-step .progress-step > a .progress-text {
  color: #7a8187;
}

.progress-step.is-active .progress-marker {
  background-color: #6cc644;
}

.progress-step.is-complete .progress-marker {
  background-color: #55a532;
}

.progress-step.is-complete::after {
  background-color: #afb3b7;
}

.progress-step:hover .progress-marker {
  background-color: #8bd26b;
}

.progress-tracker--center {
  .progress-step {
    text-align: center;
  }

  .progress-step:last-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .progress-step::after {
    right: -50%;
  }

  .progress-marker {
    margin-left: auto;
    margin-right: auto;
  }
}

.progress-tracker--right {
  .progress-step {
    text-align: right;
  }

  .progress-step:last-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .progress-step::after {
    right: calc(-100% + 1rem);
  }

  .progress-marker {
    margin-left: auto;
  }
}

.progress-tracker--border {
  padding: 0.25rem;
  border: 2px solid #afb3b7;
  border-radius: 2.5rem;
}

.progress-tracker--spaced .progress-step::after {
  width: calc(100% - 2.5rem);
  margin-right: 1.25rem;
}

.progress-tracker--word {
  padding-right: 2.3333rem;
  overflow: hidden;

  .progress-text {
    display: inline-block;
    white-space: nowrap;
  }

  .progress-title {
    margin: 0;
  }
}

.progress-tracker--word-center {
  padding-right: 2.3333rem;
  padding-left: 2.3333rem;

  .progress-text {
    padding-right: 0;
    padding-left: 0;
    transform: translateX(calc(-50% + 1rem));
  }
}

.progress-tracker--word-right {
  padding-right: 0;
  padding-left: 2.3333rem;

  .progress-text {
    padding-left: 0;
    transform: translateX(calc(-100% + 2rem));
  }
}

.progress-tracker--text .progress-step:last-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.progress-tracker--text-top {
  .progress-step::after {
    top: auto;
  }

  .progress-text {
    height: 100%;
  }

  .progress-marker {
    bottom: 2rem;
  }
}

.progress-tracker--text-inline {
  .progress-step {
    display: -webkit-box;
    display: flexbox;
    display: flex;
  }

  .progress-text {
    position: relative;
    z-index: 30;
    max-width: 70%;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }

  .progress-title {
    margin: 0;
  }
}

.progress-tracker--square {
  .progress-step {
    padding-top: 0;
  }

  .progress-marker {
    transform: scaleX(0.33) translateY(-0.875rem);
    border-radius: 0;
  }
}

@media (width <= 399px) {
  .progress-tracker-mobile {
    overflow-x: auto;
  }

  .progress-tracker-mobile .progress-tracker {
    min-width: 200%;
  }
}

.progress-tracker--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  .progress-step {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }

  .progress-step::after {
    right: auto;
    top: 1rem;
    left: 0.875rem;
    width: 0.25rem;
    height: 100%;
  }

  .progress-marker {
    position: absolute;
    left: 0;
  }

  .progress-text {
    padding-top: 0.5rem;
    padding-left: 3rem;

    .progress-step:not(:last-child) {
      padding-bottom: 1.5rem;
    }
  }
}

.anim-ripple .progress-marker::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  background: rgb(0 0 0 / 30%);
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  transition-duration: 0;
  transition-timing-function: ease-out;
}

.anim-ripple .progress-marker:active::before,
.anim-ripple .progress-step > a:active .progress-marker::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
  transition-duration: 0s;
}

.anim-ripple-large .progress-marker::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  background: rgb(0 0 0 / 30%);
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  transition-duration: 0;
  transition-timing-function: ease-out;
  width: 200%;
  height: 200%;
}

.anim-ripple-large .progress-marker:active::before,
.anim-ripple-large .progress-step > a:active .progress-marker::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
  transition-duration: 0s;
}

.anim-ripple-double .progress-marker::before,
.anim-ripple-double .progress-marker::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  transition-duration: 0;
  transition-timing-function: ease-out;
  background: none;
  border: 3px solid rgb(0 0 0 / 30%);
}

.anim-ripple-double .progress-marker::after {
  transition-delay: 0;
}

.anim-ripple-double .progress-marker:active::before,
.anim-ripple-double .progress-marker:active::after,
.anim-ripple-double .progress-step > a:active .progress-marker::before,
.anim-ripple-double .progress-step > a:active .progress-marker::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
  transition-duration: 0s;
}

.anim-path .progress-step::after {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(50%, #cacdcf),
      color-stop(50%, #afb3b7));
  background-image: linear-gradient(to right, #cacdcf 50%, #afb3b7 50%);
  background-size: 200% 100%;
  background-position: 0% 100%;
  transition: background-position 0 ease-out;
}

.anim-path .progress-step.is-complete::after {
  background-position: -100% 100%;
}

.progress-step:hover .progress-marker {
  background-color: #cacdcf;
  cursor: inherit;
}

.progress-step.is-complete .progress-marker,
.progress-step.is-active .progress-marker {
  color: white;
}

.progress-step.is-active .progress-text {
  color: #275582;
}

.progress-step.is-complete .progress-text {
  color: #55a532;
}

.progress-step.has-danger {
  .progress-marker {
    background-color: #bd2c00;
  }

  .progress-text {
    color: #bd2c00;
  }
}

.progress-step.is-active {
  .progress-marker {
    background-color: #387bbd;
  }

  .progress-text {
    color: #387bbd;
  }

  .progress-description {
    font-weight: bold;
  }
}

.progress-step {
  .progress-marker {
    color: #e4e6e7;
  }

  .progress-text {
    color: #cacdcf;
  }

  > a {
    text-decoration: none;

    .progress-marker {
      background-color: #7a8187;
    }

    .progress-text {
      color: #7a8187;
    }
  }

  > a:hover {
    .progress-marker {
      background-color: #275582;
    }

    .progress-text {
      color: #275582;
    }
  }

  > a.disabled {
    pointer-events: none;
    cursor: not-allowed;

    .progress-marker {
      background-color: #cacdcf;
      color: #e4e6e7;
    }

    .progress-text {
      color: #cacdcf;
    }
  }
}

.progress-tracker {
  margin: 0;
}

@media (width <= 576px) {
  .progress-tracker {
    margin-bottom: 1rem;
  }
}

.progress-title {
  font-size: 1.25rem;
}

.progress-description {
  font-size: 0.85rem;
  line-height: 1.1;
}

@media (width <= 992px) {
  .progress-description {
    font-size: 0.75rem;
  }
}

.progress-tracker--vertical.progress-tracker--spaced .progress-step::after {
  height: calc(100% - 2.5rem);
  margin-top: 1.25rem;
}

.progress-marker {
  z-index: inherit;
}